.flex-container {
  display: flex;

}

.flex-child {
  flex: 1;
  text-align: center;
}

.flex-child:first-child {
  margin-right: 5px;

}

#main-card-stakkid-button-4 {
  background-color: #4ec251;
  border: none;
  color: white;
  border-radius: 2px;
  padding: 3px 15px;
  font-weight: bolder;
  font-size: 20px;
  /* padding: 1px 1px; */
  /* display: flex; */
  /* flex: 1 1 0%; */
}

#main-card-stakkid-button-4:disabled {
  background-color: #6d4d95;
}
