.rv-xy-plot__axis--vertical text {
  font-size: 14px;
}

.rv-xy-plot__axis--horizontal text {
  font-size: 14px;
}

.rv-xy-plot {
  font-size: 14px;
  border-color: antiquewhite;
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
  #bar-plot {
    width: 10%;
  }
}
