

.home-vdo-thumbnail {
  background-color: #13b3e6;
  /* background-image: url(../assets/images/Screen\ Shot\ 2021-11-22\ at\ 12.11.41\ PM.png); */
  background-position: center;
  height: 10em;
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  top: 100px;

  position: static;
  /* top: 550px; */
  width: auto;

  /* right: auto; */
  /* left: 400px; */
  /* bottom: auto; */
  z-index: 511;
  padding: 21px 21px 0;
  /* border-radius: 5px 5px 5px 5px; */
  margin-bottom: 15px;
  /* border-bottom: 9px solid #18355f; */
}
/* footer text */

.slide-title-31 {
  max-width: 25%;
  text-align: center;

  position: relative;
  margin-top: -25%;
  margin-left: 7%;
}

.slide1 {
  position: relative;
  text-align: center;

  /* object-fit: cover; */
  /* height: 595px; */
}

.slide1-text {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.slide2 {
  position: relative;
  text-align: center;

  /* object-fit: cover; */
  /* height: 595px; */
}

.slide2-text {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.slide3 {
  position: relative;
  text-align: center;

  /* object-fit: cover; */
  /* height: 595px; */
}

.slide3-text {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

#show_bg {
  background-image: url('../assets/images/AdobeStock_361099167-Recovered.png');

  width: 100%;
  height: 630px;

  background-size: cover;
  color: white;
  background-position: center;
  position: static;
  /* object-fit: 50%; */
}

.background {
  background: url() repeat;
  border: 2px solid black;
}

.img-fluid {
  object-fit: cover;
  /* position: fixed; */
}

.transbox {
  position: absolute;
  text-align: center;
  width: 100%;
  /* border: 1px solid black; */
  /* opacity: 0.6; */
}

div.transbox h3 {
  margin-top: -30%;
  text-align: center;

  margin-left: -60%;
  font-size: 50px;
  font-family: 'Nunito Sans', sans-serif;
  /* font-weight: bolder; */
  color: #6d4d95;
}

/* hero css */
#main-landing-photo-containerr {
  object-fit: cover;
  width: 100%;
  /* height: 100%; */
  /* margin-bottom: 30px; */
  position: relative; /* z-index: -1; */
}

.statement {
  color: #fff;
  margin-left: 20%;
}

.footer-text {
  color: #fff;
}

.hero-container {
  /* background: url('/images/AdobeStock_222880408 (1).png') center center/cover no-repeat; */
  /* height: 100vh; */
  width: 100%;
  /* display: flex; */
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: -28%;
  margin-left: -45%;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }

  #show_bg {
    /* position: absolute; */
    /* height: 100%; */
    width: auto;

    /* height: 630px; */
    /* object-fit: cover; */
    /* background-size: cover; */
    /* color: white; */
    /* background-position: center; */
    /* position: static; */
    /* object-fit: 50%; */
  }

  .header-container {
    box-sizing: border-box;
  }

  .home-substance {
    text-align: center;
  }
}

.home-fontt {
  color: white;
  font-family: Montserrat, Arial;

  /* text-align: center; */
  font-weight: 900;
  margin-top: 3px;
  /* padding: 0; */
  /* padding-top: 23px; */
  line-height: normal;
  font-size: 100%;
}
i.fa.fa-youtube-play {
  font-size: 85px;
  color: #494949;
  margin-top: 2px;

  /* padding-bottom: 30px; */
}

.home-vdo-thumbnaill:hover,
i.fa.fa-youtube-play:hover,
i.fa.fa-youtube-play:active {
  color: red;
}

@media (max-width: 767px) {
  .home-vdo-thumbnail {
    display: none;
  }

  div.transbox h3 {
    text-align: center;
    margin: 2%;
    font-size: 20px;
    font-family: Roboto bold, Arial;

    font-weight: bolder;
    color: #ffffff;
  }
}
