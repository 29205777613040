.reset-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh; /* This will make the container take the full viewport height */
  /* background-color: #f0f0f0; Add a background color if needed */
}

.rating-wrapper {
  position: relative;
  display: inline-block;
}

.rating, .rate {
  color: #fcc201;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
}
.rating-wrapper::before,
.rating::before {
  font-family: 'FontAwesome';
  content: '\f005 \0020 \f005 \0020 \f005 \0020 \f005 \0020 \f005';
}

.rate-wrapper::before,
.rate::before {
  font-family: 'FontAwesome';
  content: '\f005 \0020';
}

.search-result-rating {
   border: 2px dashed blue; 
   display: 'inline-flex';
   align-self:   'center'; 
}

.reset-textBox {
  margin-bottom: 13px;
  border-radius: 20px;
  padding: 5px 15px;
  /* font-weight: bold; */
  /* border: none; */
  font-size: 17px;
  font-family: 'Nunito Sans', sans-serif;
}

.reset-btn {
  background-color: #6d4d95;
  color: white;
  border-radius: 20px;
  padding: 5px 15px;
  font-weight: bold;
  border: none;
  font-size: 17px;
  font-family: 'Nunito Sans', sans-serif;
}