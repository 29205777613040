.software-container {
  max-width: 1440px;
  margin: 50px auto;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  font-family: 'DM Sans', sans-serif;
  padding: 30px;
}

.survey-panel {
  background: #edf2eb;
  max-width: 420px;
  margin-left: auto;
  display: flex;
  border-radius: 20px;
}

.ref-com {
  background: #6D4D95;
  border-radius: 10px;
  margin: 0px auto;
  color: #fff;
  padding-left: 10px;
  padding-right: 33px;
  min-width: 360px;
}

.rectangle-block {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto 20px auto;
}

.ref-com li {
  margin-bottom: 3px;
}

.ad-placeholder {
  background: #cfc4dc;
  border-radius: 10px;
  height: 480px;
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 110%;
  margin-right: 0px;

  /* identical to box height, or 42px */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: rgba(33, 33, 33, 0.07);
}

.ad-placeholder img {
  border-radius: 10px;
  height: 400px;
  width: 960px;
}

.ad-placeholder-2 {

  padding-top: 10px;
   font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 110%;
  margin-right: 0px;
  margin-top: 1rem;
  /* identical to box height, or 42px */
  display: flex;
  /* align-items: start; */
  /* letter-spacing: -0.02em; */

  /* color: rgba(0, 0, 0, 0.07); */
}

.video-img {
  width: 100%;
}

.company-title h1 {
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
  font-size: 56px;
  color: #00002B;
  text-decoration: none;
}

.company-title a:hover {
  text-decoration: none;
}

.title_color {
  color: #00002B;
}

.company-short-des {
  font-size: 18px;
  line-height: 28px;
  font-family: 'DM Sans';
}

.company-des {
  font-size: 16px;
  line-height: 28px;
}

.text-centerr {
  align-items: center !important;
 
}

.product-img {
  /* border: 1px solid rgba(109, 77, 149, 0.1); */
  border-radius: 20px;
  height: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 20px;
  background: #6D4D95;
  color: #fff;
}

#software-link  {
  background: #F5F3F8;
  border-radius: 20px;
  padding: 10px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #6D4D95;
  display: flex;
  margin-top: 20px;
}

.software-stakkid-button {
  border: none;
  height: 42px;

  padding: 5px 15px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 20px;
  background: #6D4D95;
  color: #fff;
}

.video-card img {
  border-radius: 14px;
}

.ref-com-icon {
  width: 50px;
}

.video-des {
  color: #261B34;
  margin-top: 10px;
}

.company-title-icon {
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.company-detail img {
  width: 30px;
}

.review-detail-com {
  display: flex;
  align-items: center;
}

.company-detail span {
  /* display: block; */
  margin-bottom: 2px;
}

.video-card {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.company-detail li::before {
  display: none;
}

.rate-card-brands {
  background-size: cover;
  background-position: center;
}

.rate-card-brands img {
  max-width: 160px;
  border-radius: 0px 20px 20px 0px;
}

.survey-panel .software-stakkid-button {
  background: #e0934f;
  padding: 5px 15px;

  color: #000;
  border-radius: 20px;
  font-weight: bold;
}

.flash-message {
  color: #18bc9c;
  margin-top: 30px;
  display: block;
}

.error-message {
  color: #e0934f;
}

.ref-com-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.remove-btn {
  background: red;
}

.video-img-bg {
  width: 315px;
  height: 215px;
  border-radius: 14px;
  background: #000;
  display: flex;
  align-items: center;
  justify-self: center;
  margin-bottom: 20px;
}

.rate-card {
  background: #EBE6F0;
  border-radius: 10px;
}

.software-modal .MuiIconButton-root {
  margin-left: auto;
  display: block;
}

#rate-description {
  font-weight: bold;
  margin-top: 20px;
  font-size: 20px;
    font-family: 'DM Sans';
}

.software-stakkid-button-rate-1 {
  background: #6D4D95;
  color: #fff;
  border-radius: 30px;
  font-weight: bold;
  font-size: 18px;  
  margin-left: 10px;
  margin-top: -1px;
  border-width: 0px;
  padding: 5px 30px;
}

.software-stakkid-button-rate {
  background: #6D4D95;
  color: #fff;
  border-radius: 30px;
  font-weight: bold;
  margin-top: 20px;
  border-width: 0px;
  padding: 10px 40px;
  margin-bottom: 40px;
}

.software-username{
  background: grey;
  color: #fff;
  border-radius: 30px;
  font-weight: bold;
  margin-top: 20px;
  border-width: 0px;
  padding: 10px 40px;
  margin-bottom: 40px;
}

.software-modal .MuiBox-root-1{
  min-width: 350px;
}

@media (max-width: 998px) {
  .survey-panel {
    margin: 30px auto;
  }

  .ref-com {
    margin: 10px 20px;
  }

  .video-card:nth-child(0) {
    margin-top: 30px;
  }
}