.terms-button {
  background: #0a015e;
  border-color: #0a015e;
  border-radius: 4px;
  padding: 3px 15px;
  line-height: normal;
  font-weight: 700;
  font-size: 1rem;
  color: #fff;
  border: 1px solid transparent;
  white-space: nowrap;
  position: relative;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  height: 42px;
}
