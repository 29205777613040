.addToolPopover {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#save_btn .button-native{
  background: '#911af8';
  border-radius: 30px;
}

.role3 {
  white-space: 'normal' !important
}

.alert-radio-label.sc-ion-alert-md {
  white-space: 'normal' !important

}

