@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Montserrat', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Montserrat, Roboto, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}


.Chart-02 {
  margin-top: 3rem;
}
.Chart-01 {
  overflow: auto;
  width: 500px;
  display: inline-block;
  vertical-align: top;
  padding-bottom: 3rem;
}
.Chart-01 .recharts-wrapper {
  width: 500px;
  height: 200px;
}
