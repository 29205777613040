.slide-writing {
    width: 100%;
    height: 100%;
    
}

.slide-rating {
    position: absolute;
    /* position: relative; */
  
    width: 645px;
    min-height: 340px;
    border-radius: 6px;
    margin-top: -41%;
    margin-left: 3%;
  
    /* text-align: left;
    justify-content: center; */
  }
  
  .slide-t {
    /* font-weight: bold; */
    font-family: 'DM Sans', sans-serif;
    font-size: 56px;
    color: #000000;
    /* margin-bottom: 1%; */
    line-height: 25px;
  }

  .slide-t-2 {
    /* font-weight: bold; */
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    color: #000000;
    padding-top: 3%;
    /* line-height: 25px;/ */
  }

  .buttons-card-1 {
    display: flex;
    justify-content: space-evenly;
  }

  .buttons-card-3 {
    display: flex;
    justify-content: space-between;
  }

  .rateBtn {
    background-color: #6d4d95;
    border-radius: 20px
  }