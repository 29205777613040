.page-padding {
}

.wrapper {
    /* max-width: 1210px; */
    height: 433px;
flex-shrink: 0;
  }

  .rec-wrapper {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), lightgray 1016.001px -71.735px / 61.651% 128.197% no-repeat;
    height: 433px;
    margin-left: -5%;

    max-width: 1210px; 

    flex-shrink: 0;
  }
  
  .ppara {
    line-height: 1.6; 
    /* font-family: Helvetica; */
    text-align: justify;
    margin: 0;
    /* font-size: 14px; */
  }
  
  .star {
    float: right;
    width: 250px;
    shape-margin:20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  

.my-scoringone {
    height: 100%;

}

.ms-title {
    font-size: '58px';

}

.ms-title-2 {
    color: #212529;
font-family: DM Sans;
font-size: 47px;
font-style: normal;
font-weight: 900;
line-height: 57.6px; /* 122.553% */


}

.apppp {
    display: flex
}

.appp {
    padding: 20px;
    
}


.ms-title-2 {
    font-family: DM Sans;
    font-weight: 500;
    width: 989px;

  
  }

.myscore-para {
    padding-left: 20px;
    margin-top: -392px;
    width: 843px;
height: 310px;
flex-shrink: 0;
color: #000;
font-family: DM Sans;
font-style: normal;
/* font-weight: 400; */
line-height: 36.6px; /* 88.511% */
}




.appp-2 {
    width: 961px;
        height: 100%;
    /* padding-top: 10%; */
    /* padding-left: 30px; */
    color: #212529;
font-family: DM Sans;
font-size: 39px;
font-style: normal;
font-weight: 400;
line-height: 48px; /* 123.077% */
}

.appp-2-p {
    width: 1250px;
    color: #212529;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.5px; /* 150% */
}

.appp-2-p-1 {
    text-align: center;
    color: #212529;
font-family: DM Sans;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 22.5px; /* 150% */
padding-top: 10px;
padding-bottom: 10px;



}

.appp-2-p {
    font-size: '18px';

}

.img-fluid2 {
    width: 100%;
    border: 0.5px solid #e2e2e2;


}

.img-fluid3 {
    
    padding-left: 10%;

}


.img-row-title {
    color: #212529;
    font-family: DM Sans;
    font-size: 39px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    padding-left: 10%;

}

.img-row {
    width: 50%;
    float: left;
max-height: 639px;
flex-shrink: 0;
}



.img-row-2 {
    width: 570px;
    padding: 0px 186.53px 0px 186.52px;
    justify-content: center;
}


.purple-box {
        width: '600px';
        background-color: '#6d4d95';
        color: 'white';
        margin-left: '25%';
        padding: '5px';
}

.purple-para {
    font-size: '24px';
        
}

.purple-para-2 {
 
        
}

.beast-para-mobile {
    display: none;

}



.purple-para-mobile {
    display: none;
}

@media only screen   
and (min-width: 1030px)   
and (max-width: 1366px)  {


.beast-para-mobile {
    display: none;

}



.purple-para-mobile {
    display: none;
}

}

@media only screen   
and (min-device-width : 768px)   
and (max-device-width : 1024px)  {


.beast-para-mobile {
    display: none;

}



.purple-para-mobile {
    display: none;
}

}



@media only screen and (max-device-width: 844px) {

    .apppp {
    display: flex;
    flex-direction: column;
    }

    .appp {
        width: 100%;
        flex: 4;
    }

    .appp-2 {
        width: 100%;
        /* height: 100%; */
        padding-left: 3px;
    }

    .img-fluid3 {
        width: 100%;
    }
    
    

    .page-padding {
        position: relative;
        /* width: auto; */
        max-width: 1440px;
        min-height: 1920px;

        /* height: 100vh; For 100% screen height */
        /* width:  100vw; For 100% screen width;  */
        margin: auto;
    }

    .purple-para-web {
       display: none;

        /* width: '5px' !important; */
    }

    .beast-para-web {
        display: none;
    
    }

    .beast-para-mobile {
        display: unset;
    
    }
    
    
    
    .purple-para-mobile {
        display: unset;
    }

}