.influencer-stack {

    display: flex;
    flex-direction: row;
    /* width: 75rem; */
    justify-content: space-around;
  
}


.Card-1 {
  margin-bottom: 30px;
  width: 300px;
  height: 380px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);
  /* box-shadow: 0px 1px 10px 1px black; */
  overflow: hidden;
}

.Card-2 {
  margin-bottom: 30px;
  width: 300px;
  height: 380px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);
  /* box-shadow: 0px 1px 10px 1px black; */
  overflow: hidden;
}

.Card-3 {
  margin-bottom: 30px;
  width: 300px;
  height: 380px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);  /* box-shadow: 0px 1px 10px 1px black; */
  overflow: hidden;
}


.upper-container {
  height: 100px;
  background: linear-gradient(to left, #b4b4b4, #ffffff);
}
.image-container img {
  background: white;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  padding: 5px;
  transform: translate(85px, 25px);
}
.image {
  /* border: 2px solid #4ca8d8; */
}
.lower-container {
  margin-top: 60px;
  height: 300px;
  background: white;
  text-align: center;
  font-family: 'titillium web', sans-serif;
}
/* .lower-container h3,
h4,
p {
  margin: 0;
  padding: 0;
}
.lower-container h3 {
  color: #2f2d2e;
  font-weight: 600;
  font-size: 1.2rem;
} */
.lower-container h4 {
  color: #6d4d95;
  font-weight: 700;
  font-size: 1.2rem;
}
.lower-container p {
  margin: 1rem;
  font-weight: 300;
  color: #000000;
  font-size: 0.9rem;
  text-align: justify;
}
