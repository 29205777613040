body {
  margin: 0;
}
.container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  width: 200px;
  height: 800px;
  /* padding-left: 200px; */
  background-color: #fff;
}

svg {
  overflow: visible;
}
