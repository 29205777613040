.post-editor {
  margin: 1em 1%;
}

.post-editor-button {
  float: right;
  margin-top: 1em;
}

.post-body {
  margin: 1em 1%;
}

#main-card-stakkid-button {
}

/* App.css */

/* App.css */

.container-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 4 columns */
  grid-gap: 20px; /* Gap between boxes */
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  padding-left: 165px;
}
#box-one-box {
  width: 350px;
  height: 279px;
  background: var(--White, #FFF);
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);
  /* box-shadow: 0px 3px 6px 0px #6d4d95; */

  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.box-one {
  border: 2px solid #6d4d95;

}

.box-two {
  border: 2px solid #ff0000; /* Red border */
}

.box-three {
  border: 2px solid #ff0000; /* Red border */
}

.box-four {
  border: 2px solid #6d4d95;

}

.box-five {
  border: 2px solid #6d4d95;
}

.box-six {
  border: 2px solid #ff0000; /* Red border */
}

.box-seven {
  border: 2px solid #ff0000; /* Red border */

}

.box-eight {
  border: 2px solid #6d4d95;
}



.logo {
  /* width: 50px; */
  height: 56px;
}

.content {
  text-align: center;
}

p {
  margin: 10px 0;
}

.learn-more {
  background-color: #ffffff;
  color: var(--Greyish, #646E73);
  border: none;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-left: 118px;
/* padding-top: 20px; */

}


@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

/* Add animation class for each box */
.box-one {
  animation: slideInFromLeft 1s ease-in-out;
}

.box-two {
  animation: slideInFromRight 1s ease-in-out;
}

.box-three {
  animation: slideInFromLeft 1s ease-in-out;
}

.box-four {
  animation: slideInFromRight 1s ease-in-out;
}

.box-five {
  animation: slideInFromLeft 1s ease-in-out;
}

.box-six {
  animation: slideInFromRight 1s ease-in-out;
}

.box-seven {
  animation: slideInFromLeft 1s ease-in-out;
}

.box-eight {
  animation: slideInFromRight 1s ease-in-out;
}
