* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body > iframe {
  pointer-events: none;
}

/* body {
  width: 1920px;
} */

body {
  font-family: Montserrat, Arial;
}
.page-footer {
  z-index: 5
}

.nav-stack {
  display: flex;
  justify-content: center;
  

  /* padding-left: 30px; */
}

.form-check-label {
  padding-left: 10px;
}

.terms-message {
  padding-left: 10%;
}

.Chart-01 {
  /* margin-left: 50px; */
}

.Chart-02 {
  /* margin-right: 40px; */
}

.Chart-03 {
  /* margin-right: 40px; */
}



.score-title {
  max-width: 15%;
  margin-top: -7px;
}
.Sign-Google {
  text-align: center;
  margin-left: 20%;
}
.socialLinks {
  position: fixed !important;
}

.float-container {
  padding: 20px;
}

.float-child {
  width: 30%;
  float: left;
  /* padding: 20px; */
}

.float-childd {
  min-width: 30%;
  float: right;
  /* padding: 20px; */
  text-align: right;
  font-size: 32px;
  color: black;
  /* padding-left: 10px; */
}

.certt {
  position: relative;
  text-align: center;

  object-fit: cover;
  line-height: normal;

  /* flex-direction: column; */
}

.cert {
  display: flex;
  text-align: center;
  margin-left: 20%;

  position: relative;
  text-align: center;

  object-fit: cover;
  line-height: normal;

  /* flex-direction: column; */
}

.certName {
  position: absolute;
  text-align: center;
  background-color: transparent;
  padding-top: 10px;
  top: 50%;
  right: 44%;
}

.certScore {
  position: absolute;
  text-align: center;
  background-color: transparent;

  padding-top: 10px;
  top: 61%;
  right: 46%;
}

.socialMediaPopper {
  top: 19% !important;
  left: 50% !important;
  right: 50% !important;
  display: flex;
  /* position: sticky !important; */
  text-align: center;
  margin-left: 46%;
  padding-top: 10px;
  padding-bottom: 10px;

  /* flex-direction: column; */
}

.socialMediaButtons {
  top: 19% !important;
  left: 50% !important;
  right: 50% !important;
  display: flex;
  position: sticky !important;
  text-align: center;
  margin-left: 43%;
  padding-top: 10px;
  padding-bottom: 10px;

  /* flex-direction: column; */
}

.socialMediaPoppers-2 {
  /* top: 19% !important; */
  /* left: 50% !important; */
  /* right: 50% !important; */
  display: flex;
  position: sticky !important;
  text-align: center;
  margin-left: 41%;
  padding-top: 10px;
  padding-bottom: 10px;

  /* flex-direction: column; */
}

.socialMediaPoppers {
  top: 19% !important;
  left: 50% !important;
  right: 50% !important;
  display: flex;
  position: sticky !important;
  text-align: center;
  margin-left: 52.5%;
  padding-top: 10px;
  padding-bottom: 10px;

  /* flex-direction: column; */
}

.socialMediaButton {
  position: sticky !important;
}

.socialShare {
  margin-top: 7px;
  margin-right: 20px;
  color: #000;
  /* padding-left: 25px; */
}

/* .socialMediaButton:hover > svg {
  height: 60px !important;
  width: 60px !important;
} */

.social-buttonn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
}
.social-buttonn1 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}

.social-button1 {
  margin-left: 5px;
}

.social-button2 {
  margin-left: 30px;
  border-radius: 10%;
}

#main-stakkid-button {
  background-color: #6d4d95;

}

#main-stakkid-button-log {
  background-color: #6d4d95;
  border-radius: 20px;
  border: none;
  height: 45px;
  width: 190px;
  font-size: 18px;
  margin: 10px;


  



}

.modalContainer2  {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer2 .footer2 {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer2 .footer2 button {
  width: 190px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
}

#cancelBtn2 {
  background-color: #6d4d95;
  border-radius: 20px;


}

.titleCloseBtn2 {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;

  padding-right: 15px;
}

.titleCloseBtn2 button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
  
}

.signin-1 {
  /* font-weight: bolder; */
  text-align: center;

  margin-left: 20%;
  padding-top: 5%;
  padding-bottom: 10%;
}

.signin {
  /* font-weight: bold; */
  text-align: center;

  margin-left: 25%;
  padding-top: 5%;
  padding-bottom: 10%;
}

.signi {
  /* font-weight: bolder; */

}

#main-landing-photo-container {
  /* background-color: #18355f; */
  /* width: 1024px; */
  /* height: 575px; */
  /* display: block; */
  object-fit: cover;
  width: 100%;
  /* position: fixed; */
  height: 100%;
  margin-bottom: 30px;

  margin-left: auto;
  margin-right: auto;
}

#card-styles:hover {
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
}

/* .middle-logo {
  margin-left: -11px;
} */

.toolandcat {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  margin-left: 20px;
}

.home-vdo-thumbnaill {
  background-color: #13b3e6;
  /* background-image: url(../assets/images/Screen\ Shot\ 2021-11-22\ at\ 12.11.41\ PM.png); */
  /* background-position: center;
  height: 12em;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; */
  width: auto;
  height: auto;
  /* position: absolute; */
  /* top: 550px; */
  align-items: center;

  /* right: 0; */
  /* left: 400px; */
  /* bottom: auto; */
  /* z-index: 111; */
  /* padding: 21px 21px 0; */
  /* border-radius: 2px; */
  padding: 5px 13px;
  border: none;
}

.home-vdo-thumbnailll {
  background-color: #13b3e6;
  /* background-image: url(../assets/images/Screen\ Shot\ 2021-11-22\ at\ 12.11.41\ PM.png); */
  /* background-position: center;
  height: 12em;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; */
  width: auto;
  height: auto;
  border: none;

  position: absolute;
  top: 0px;

  right: 0;
  /* left: 400px; */
  bottom: auto;
  /* z-index: 111; */
  /* padding: 21px 21px 0; */
  /* border-radius: 0px; */
  /* padding: 5px 13px; */
}

.wrapperr {
  width: 100%;
  display: flex;
  justify-content: center;

  /* height: 400px; */
  /* border: 1px solid black; */
}

.nav-buttonn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  position: relative;
  margin: -20px -50px;
  width: 170px;
  top: 50%;
  /* left: 50%; */
  /* right: 25%; */
  background-color: #6d4d95;
  border: hidden;
  border-radius: 30px;
}

.home-fonttt {
  color: white;
  /* text-align: center; */
  font-weight: 900;
  font-family: 'Nunito Sans', sans-serif;

  /* margin-bottom: 15px; */
  padding: 4px;
  padding-right: 10px;
  line-height: normal;
  font-size: 100%;
}

.home-substanceee {
  display: flex;
  flex-direction: row;
  justify-content: center;

  /* align-items: center; */
}

/* .header-containerr {
  box-sizing: border-box;
} */

.home-substancee {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* text-align: center; */
}
.align-middle {
  padding-right: 10px;
}

.home-fontt {
  color: rgb(0, 0, 0);
  align-items: center;
  font-weight: 900;
  margin-bottom: 7px;
  padding: 1px;
  padding-right: 10px;
  padding-left: 10px;

  line-height: normal;
  font-size: 100%;
}
i.fa.fa-youtube-play {
  font-size: 85px;
  color: #f70101;
}
i.fa.fa-youtube-play:hover,
i.fa.fa-youtube-play:active {
  color: red;
}

@media (max-width: 1024px) {
  .home-vdo-thumbnaill {
    display: none;
  }

  #main-navbar-container-2 {
    background-color: #1b3a22;
    /* margin-bottom: -35px; */
    height: 156px;
  }

}


@media (max-width: 1238px) {

  #navbar-links {
  
    font-size: 19px;
   
  }
}




@media (min-width: 1366px) {
  .home-vdo-thumbnailll {
    display: none;
  }

 
}

h1,
h2,
h3 {
  font-family: 'Nunito Sans', sans-serif;
}

#navbar-links {
  color: #6d4d95;
  margin: 8px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  clear: both;
}

#signing-links {
  color: white;
  margin: 8px;
  clear: both;
}

#signing-links:hover {
  background: white;
  color: #18355f;
}

#main-navbar-container {
  background-color: #e5e5e5;
  /* position: fixed; */
  margin-top: 10px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
}



.navbar-nav {
  background-color: #ffffff;
  position: relative;
  z-index: 999;
}

#main-navbar-container-2 {
  /* background-color: #ffffff; */
  background: rgba(255, 255, 255, 1);
  /* margin-bottom: -35px; */
  height: 80px;
}

#main-navbar-container-3 {
  background-color: #e5e5e5;

  /* margin-bottom: -35px; */
  height: 51px;
}

#stakkid-nav-logo {
  margin-bottom: 8px;
  width: 135px;
}

#stakkid-nav-logo-footer {
  margin-top: 10px;
  width: 135px;
}

#stakkid-nav-logo-2 {
  margin-left: 11px;
  margin-bottom: 25px;
  width: 150px;
}

#choose-martech-tools-container {
  background-color: #18355f;
  /* height: 590px; */
  width: 100%;
  margin: 0;
  overflow: hidden;
}

#footer-links {
  color: #43498f;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 15px;
  margin: 8px;
  margin-top: 18px;
}

#footer-links-contact {
  color: #43498f;
  font-family: 'Nunito Sans', sans-serif;
  /* font-size: 15px; */
  margin: 8px;
  margin-top: 18px;
}

#stakkid-footer-link {
  color: white;
  margin: 8px 9%;
}

#stakkid-logo-for-text {
  width: 100px;
  height: 100px;
  margin-bottom: 5px;
}

/* .img-row {
  display: flex;
} */

.img-fluid2 {
  flex: 50%;
  padding: 5px;
}

.IonCardWrap {
  margin-top: -10%;
}

#main-landing-photo-container {
  /* background-color: #18355f; */
  /* width: 1024px; */
  /* height: 575px; */
  /* display: block; */
  object-fit: cover;

  margin-left: auto;
  margin-right: auto;
}

#main-landing-bottom-photo {
  width: 1024px;
  height: 575px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* #martech-tools-begin-button {
  background-color: rgb(10, 1, 94);
  height: 70px;
  width: 175px;
  color: white;
  font-size: larger;
  margin: 10%;
} */

#choose-martech-tools-rightsplit {
  text-align: center;
  float: right;
  padding-bottom: 20px;
}

#choose-martech-tools-leftsplit {
  text-align: center;
  float: left;
  padding-bottom: 20px;
}

.user-email {
  font-family: 'Nunito Sans', sans-serif;
  font-color: #6d4d95;
}

#main-card-stakkid-button {
  background-color: #6d4d95;
  color: white;
  border-radius: 30px;
  padding: 15px 15px;
  border: none;
  
}
#main-card-stakkid-button-2 {
  background-color: #6d4d95;
  color: white;
  border-radius: 20px;
  padding: 5px 15px;
  font-weight: bold;
  border: none;
  font-size: 17px;
  font-family: 'Nunito Sans', sans-serif;

  /* display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  align-items: inherit; */
}

.main-card-stakkid-button {
  background-color: #6d4d95;
  color: white;
  border-radius: 30px;
  padding: 3px 15px;
  font-weight: bold;
  font-size: 20px;
  
}

.main-card-stakkid-buttons {
 
  display: flex;
  justify-content: center;
}

/* .input-wrapper {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  align-items: inherit;
} */

.addToolPopover {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#main-card-center {
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 28%;
  left: 34%;
   
  


}

#main-card-stakkid-button-3-1 {
  background-color: #6d4d95;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 3px 15px;
  font-weight: bolder;
  font-size: 20px;
  /* padding: 1px 1px; */
  min-width: 227px;
  display: flex;
  flex: 1 1 0%;
  /* flex-direction: column; */
  justify-content: center;
  margin-left: 60.2%;
  margin-right: 45%;
  margin-bottom: 5px;
}

#main-card-stakkid-button-3-1-2 {
  background-color: #6d4d95;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 3px 15px;
  font-weight: bolder;
  font-size: 20px;
  /* padding: 1px 1px; */
  min-width: 227px;
  display: flex;
  flex: 1 1 0%;
  /* flex-direction: column; */
  justify-content: center;
  margin-left: 60%;
  margin-right: 45%;
}

#main-card-stakkid-button-3 {
  background-color: #6d4d95;
  color: white;
  border: none;
  /* padding: 3px 15px; */
  font-weight: bolder;
  font-size: 20px;
  margin: 20px;

  /* padding: 1px 1px; */
  /* display: flex; */
  /* flex: 1 1 0%;
  flex-direction: column;
  justify-content: center; */
  /* margin-left: 63%;
  margin-right: 45%; */
}

#main-card-stakkid-button-5 {
  background-color: #6d4d95;
  color: white;
  border: none;
  /* padding: 3px 15px; */
  font-weight: bolder;
  font-size: 20px;
  /* padding: 1px 1px; */
  /* display: flex; */
  /* flex: 1 1 0%;
  flex-direction: column;
  justify-content: center; */
  /* margin-left: 63%;
  margin-right: 45%; */
}

.column {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#main-stakkid-button:focus {
  outline: 0;
  background-color: rgb(66, 133, 244);
}

#disabled-stakkid-button {
  background-color: lightgray;
  border-radius: 13px;
  padding: 3px 15px;
  margin: 10px;
}

#disabled-stakkid-button:focus {
  outline: 0;
}

#card-styles {
  padding: 20px;
  width: 18rem;
}

.page-padding {
  padding: 13% 8%;
  margin-bottom: 23%;

  /* text-align: justify; */
}

#profile-page-text {
  font-size: 17px;
  color: gray;
}

#profile-input {
  border-radius: 15px;
  width: 250px;
  height: 35px;
  margin: 0 3px;
  padding-left: 5px;
}

#profile-input:focus {
  outline: 0;
}

#profile-text {
  font-size: 18px;
  display: flex;
  justify-content: center;
}

#company-profile-dropdown {
  background-color: transparent;
  color: gray;
  margin-left: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 170px;
}

.company-profile-dropdown-arrow {
  background-color: gray;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#disabled-profile-button {
  background-color: transparent;
  border: none;
}

#disabled-profile-button:focus {
  outline: 0;
}

#license-blocks {
  border: 1px solid black;
  margin: 5px;
  padding: 10px;
}

#billing-float-margins {
  margin: 10px;
  float: left;
}

#billing-total-box {
  border: 1px solid black;
  width: 175px;
  height: 100px;
  margin: 0 auto;
}

#bundle-box {
  border: 1px solid black;
  height: 180px;
  width: 150px;
  padding: 10px;
  margin: 30px;
  text-align: center;
}

#bundle-box-text {
  float: left;
  margin-top: -10px;
  margin-left: 10px;
}

#create-account-modal {
  text-align: center;
}

/* #create-account-modal-2 {
  border-radius: 5px;
padding-left: 20px

} */

#linkedin-login-button {
  background-color: #f5f5f5;
  height: 45px;
  width: 100%;
  margin: 0;
  border-radius: 5px;
}
#please-note-container {
  background-color: lightgrey;
}

#about-page-card {
  margin: 10px;
}


#bold-fonte1 {
  /* font-weight: bold; */
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  justify-content: center;
  margin-top: 60px;

  
}

#bold-fonte-nav {
  /* font-weight: bold; */
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  justify-content: center;
  /* margin-top: 60px; */

  
}

#bold-fonte {
  /* font-weight: bold; */
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  justify-content: center;
  margin-top: 60px;

  
}

#bold-font {
  /* font-weight: bold; */
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  justify-content: center;
  margin-top: -30px;

  
}

#bold-fonteee {
  /* font-weight: bold; */
  /* font-family: 'Open Sans', sans-serif; */
  margin-top: -20px;
  

}

#bold-fontee {
  font-weight: bolder;
  /* color: #18355f; */
  font-size: 43px;
  /* padding-left: 1%; */
  padding-top: 10px;
  padding-bottom: 50px;
  /* width: 100px; */
  display: flex;
  justify-content: center;
}

.resource-title {
  padding-bottom: 1%;
  padding-right: 1%;
}

.community-title {
  padding-bottom: 0.5%;
  padding-right: 1%;
}

.page-head {
  display: flex;
  flex-direction: row;
}

#stakkid-influencers-photo {
  /* border-radius: 50%; */
  width: 85%;
  padding: 0px;
  align-self: center;
}

#stakkid-influencers-card {
  /* border-radius: 5%; */
  padding: 10px;
  margin-bottom: 10px;
  /* background-color: rgb(237, 237, 237); */

  width: 20rem;
}

#stakkid-influencers-title {
  /* padding: 0px; */
  width: 100%;
  background-color: #18355f;
  color: white;
  text-align: center;
}

#stakkid-community-search {
  width: 50%;
  height: 40px;
  border-radius: 23px;
  padding-left: 45px;
  background: no-repeat scroll 7px;
}

#stakkid-community-influencers-container {
  display: flex;
  flex-direction: row;
  /* width: 20rem; */
}

#stakkid-community-qa-container {
  /* margin-left: 0;
  margin-right: auto; */
  /* width: 50rem; */
  /* float: left; */
}

#resources-partners-header-container {
  border-bottom: 3px solid black;
  /* padding-left: 100px; */
  padding-top: 40px;
}

#resources-teb-image {
  width: 300px;
  height: 150px;
  float: left;
}

#learn-more-button {
  color: white;
  background-color: lightgreen;
}

#resources-learn-more-container {
  padding: 50px 100px;
  border-bottom: 2px solid gray;
}

#resources-individual-partner-container {
  padding: 50px;
}

#resources-individual-partner-photo {
  width: 115px;
  height: 115px;
  float: left;
}

/* #experts-bench-text-container {
  padding-left: 20px;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.addTooltipPopover .popover-content {
  padding: 20px;
}

.addTooltipPopover-2 {
  padding-right: 20px;
}

.secondary-assesment-bx .MuiAccordion-root {
  box-shadow: none;
}
.secondary-assesment-bx .MuiAccordion-root.last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}
.secondary-assesment-bx .MuiAccordion-root:before {
  background-color: rgba(0, 0, 0, 1);
  opacity: 1 !important;
  display: block !important;
  visibility: visible;
}
.secondary-assesment-bx .MuiAccordion-root.Mui-expanded:before {
  opacity: 1;
}
.secondary-assesment-bx .MuiAccordionDetails-root .ass-card {
  height: 100%;
  min-height: 300px;
  width: 340px;
}
.InputDiv {
  margin: 0 0 1rem;
}
.SignGoogle {
  padding-right: 0%;
  align-items: center;
  /* background: #fefefe;
  border: 1px solid #fefefe;
  padding: 0.5rem;
  color: #13b3e6;
  display: block;
  border-radius: 0.3rem; */
}
.InputDiv label {
  margin: 0 0 0.3rem;
  text-align: left;
  display: block;
  font-weight: 600;
}
.InputDiv input {
  border: 1px solid #e5e5f5;
  border-radius: 6px !important;
  margin: 0 !important;
  padding: 0 1.2rem !important;
  width: 100% !important;
  position: relative;
  background: #fff;
  height: 48px !important;
  display: flex;
  align-items: center;
}
.BtnWrap button {
  background: #0a015e;
  border-color: #0a015e;
  border-radius: 4px;
  padding: 0 1.5rem 0.2rem;
  line-height: normal;
  font-weight: 700;
  font-size: 1rem;
  color: #fff;
  border: 1px solid transparent;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;

}

.BtnWrapp button {
  background: transparent;
  border-color: #0a015e;
  border-radius: 4px;
  padding: 0 1.5rem 0.2rem;
  line-height: normal;
  font-weight: 700;
  font-size: 1rem;
  color: #fff;
  border: 1px solid transparent;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 0px;
}

.BtnWrappp button {
  background: transparent;
  border-color: #0a015e;
  border-radius: 4px;
  padding: 0 1.5rem 0.2rem;
  line-height: normal;
  /* font-weight: 700; */
  font-size: 1.7rem;
  color: #fff;
  border: 1px solid transparent;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
}

.modalTitle {
  align-items: center;
}
.modalTitle .modal-header {
  border: 0;
  padding: 0;
}

.my-score{
 border: 8px solid #911af9;
 width: 100px;
 height: 100px;
 border-radius: 50%;
 color: #fff;
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: #e84521;
 font-size: 40px;
 margin: 0px auto;
}

.progress-stepper{
  display: flex;
}


@media (max-width: 1200px) {
  .progress-stepper{
    display: block;
  }
}

@media (max-width: 767px) {
.ms-title {
  font-size: 4px;

}

  #card-styles {
    width: 100%;
  }
  #bar-plot {
    width: 200%;
    border-color: white;
    outline: white;
  }
  .page-padding {
    padding: 30px 15px;
    margin-bottom: 13%;
  }
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 24px;
  }
  .custom-stepper {
    padding: 0;
  }
  .custom-stepper span svg {
    color: rgb(204 232 242);
  }
  div.custom-stepper {
    padding: 0;
    flex-direction: column;
    position: relative;
    margin-bottom: 20px;
  }
  .custom-stepper > div {
    padding-bottom: 15px;
    padding-left: 0;
    z-index: 1;
  }
  .custom-stepper .MuiStepConnector-alternativeLabel {
    display: none;
  }
  .custom-stepper .MuiStepLabel-root.MuiStepLabel-alternativeLabel {
    align-items: center;
    flex-direction: initial;
  }
  .custom-stepper .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 0;
    padding-left: 10px;
  }
  .custom-stepper:after {
    position: absolute;
    content: ' ';
    height: calc(100% - 25px);
    background: #bdbdbd;
    width: 1px;
    left: 11px;
    top: 23px;
    z-index: 0;
  }
  .custom-stepper > div:last-child {
    padding-bottom: 0;
  }
  .custom-stepper .MuiStepIcon-root.MuiStepIcon-completed {
    fill: #e84521;
    background: #ffffff;
  }
  .custom-modal {
    top: 0 !important;
    width: 100% !important;
    padding: 15px;
    display: flex;
    align-items: center;
  }
  .custom-modal .MuiPaper-root {
    padding: 25px 15px 25px 15px !important;
    position: relative;
    max-width: 290px;
  }
  .custom-modal ul li:last-child {
    padding-bottom: 0;
  }
  .custom-modal button {
    padding: 0;
    min-width: 0;
    min-width: initial;
    position: absolute;
    right: 0;
    top: -30px;
    color: #fff;
  }
  .custom-modal h2 {
    font-size: 22px !important;
    margin-bottom: 18px;
    color: #18355f;
    width: 100%;
    text-align: center;
    font-weight: bold !important;
  }
  .custom-modal ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .custom-modal ul li {
    padding: 0 0 12px;
    position: relative;
    padding-left: 15px;
  }
  .custom-modal ul li h5 {
    font-size: 15px;
  }
  .custom-modal ul li:after {
    position: absolute;
    left: 0;
    top: 7px;
    background: #000;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    content: '';
  }
  .secondary-assesment-cs .MuiSvgIcon-root {
    font-size: 24px !important;
  }
  .secondary-assesment-cs .MuiTypography-body1 {
    width: 100%;
  }
  .secondary-assesment-cs .MuiGrid-spacing-xs-2 {
    margin: 0;
    width: 100%;
    margin-top: 0 !important;
  }
  .secondary-assesment-bx.secondary-assesment-cs .MuiAccordion-root {
    box-shadow: none;
    background: #eee;
    margin-bottom: 10px;
    padding: 0 0 0 10px;
    border-bottom: 0;
  }
  .secondary-assesment-bx.secondary-assesment-cs .MuiAccordion-root:before {
    opacity: 0 !important;
  }
  .secondary-assesment-cs .MuiAccordionSummary-content h2 {
    font-size: 16px;
    margin: 0;
  }
  .secondary-assesment-cs .MuiSvgIcon-root {
    font-size: 24px !important;
  }
  .secondary-assesment-bx.secondary-assesment-cs
    .MuiGrid-spacing-xs-2
    > .MuiGrid-item {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  .secondary-assesment-bx.secondary-assesment-cs
    .MuiAccordionDetails-root
    .ass-card {
    padding: 0 !important;
    min-height: 200px;
    width: 100%;
  }
  .secondary-assesment-cs .MuiAccordionDetails-root {
    padding-left: 0;
    padding-right: 8px;
  }
  .makeStyles-modal-6 {
    width: 100% !important;
    top: 0 !important;
    overflow: auto;
  }
  .footer-copyright {
    flex-wrap: wrap;
  }
  .row.card-container {
    margin: 0 0 25px !important;
  }
  .row.card-container .card-deck {
    width: calc(100% - 30px) !important;
  }
  /* .hydrated {
    display: block;
  } */
  .MuiPaper-root.ass-card {
    padding: 1rem !important;
  }
  .MuiListItem-gutters {
    padding: 10px 0;
  }
  .ios.card-content-ios {
    padding: 15px 10px 15px 0;
  }
  .sc-ion-searchbar-ios-h {
    padding: 0 0 15px;
  }
  .ios.title-default {
    padding: 10px 0 0;
    text-align: left;
  }
  ion-select.ios {
    max-width: 100%;
    padding: 0;
    width: 100%;
    position: relative;
    display: flex;
    vertical-align: top;
    align-items: center;
  }
  .select-icon-inner {
    left: 0;
  }
  :host(.button-full) .button-native {
    padding: 0.7rem 1rem;
    height: auto;
  }
  .recharts-wrapper {
    position: relative;
    cursor: default;
    width: 280px;
    height: 200px;
    overflow: visible;
    margin: 0 auto !important;
    padding: 0;
  }
  .recharts-wrapper svg {
    width: 50%;
    position: absolute;
    top: -60px;
  }
  .rv-xy-plot {
    width: 100% !important;
    height: 280px !important;
    transform: scale(0.7) !important;
    margin: -40px 0 30px 0 !important;
  }
  #resources-partners-header-container {
    border-bottom: 3px solid black;
    padding: 20px 10px 0;
  }
  td {
    display: block;
    width: 100%;
    padding: 0.5rem;
  }
  #resources-learn-more-container {
    padding: 15px;
    border-bottom: 2px solid gray;
  }
  table {
    width: 90%;
    margin: 0 auto;
  }
  #stakkid-community-influencers-container {
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }
  #stakkid-community-search {
    width: 100%;
    background-size: 20px;
    background-position: 15px 50%;
  }
  #main-card-stakkid-button {
    max-width: 80%;
    float: none !important;
    margin: 0 auto;
    display: block;
  }
  #stakkid-influencers-card {
    width: 100%;
  }
  ion-select {
    display: flex !important;
    width: 100%;
    min-width: 100%;
  }
}

@media (max-width: 420px) {
  .IonCardWrap {
    max-width: 400px;
    margin: 15px;
  }
}

@media (min-width: 1281px) {
  #main-navbar-container-3 {
    display: none;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
  .card-deck {
  }
}

/* iphone 8 */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
  /* Styles */
  .custom-modal .MuiPaper-root {
    padding: 15px 0px 15px 0px !important;
    position: relative;
    max-width: 400px;
    border: 3px solid #13b3e6;
  }

  .IonCardWrap {
    padding: 25px 15px 25px 15px !important;
    /* position: fixed;
    bottom: 60px;
    left: -7px;
    right: 0; */
    width: auto;
    border: 3px solid #13b3e6;
  }

  .toolbar-title {
    margin-bottom: 60px;
  }

  .item-inner {
    padding-bottom: 0%;
  }

  .makeStyles-unorderedList-6 {
    padding: 10px 40px 10px 40px;
  }
  .social-buttonn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .social-buttonn1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .social-button1 {
    margin-left: 70%;
    margin-bottom: 10px;
  }

  .social-button2 {
    margin-left: 70%;
  }
  .signin {
    margin-left: 20%;
  }
}

/* iphone 10 */
@media only screen and (min-device-width: 375px) and (max-device-width: 844px) {
  /* Styles */
  .ms-title {
    font-size: 34px !important;
  
  }

  .ms-title-2 {
    font-size: 24px !important;
  
  }
  .Appp-1 {
    display: none !important;

  }

 .text-center {
  font-size: 18px !important;

 }

 .software-stakkid-button-rate-1 {
  margin-right: 2% !important;
 }

 
  .custom-modal .MuiPaper-root {
    padding: 15px 0px 15px 0px !important;
    position: relative;
    height: 550px;
    max-width: 400px;
    border: 3px solid #13b3e6;
  }

  .custom-modal h5 {
    font-size: 18px !important;
    margin-bottom: 18px;
    color: #18355f;
    width: 100%;
    /* text-align: center; */
    font-weight: bold !important;
  }

  .custom-modal h6 {
    font-size: 13px !important;
    margin-bottom: 18px;
    padding: 10px;
    /* color: #18355f; */
    width: 100%;
    /* text-align: center; */
    /* font-weight: bold !important; */
  }

  .social-buttonn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .social-buttonn1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .social-button1 {
    margin-left: 50%;
    margin-bottom: 10px;
  }

  .social-button2 {
    margin-left: 50%;
  }
  .signin {
    margin-left: 15%;
  }

  #main-navbar-container-3 {
    background-color: #ffffff;
    margin-bottom: 30px;
    height: 51px;
  }

  .image {
    width: 340px;
  }

  #stakkid-nav-logo-footer {
    margin-bottom: 5px;
    width: 80px;
  }

  #footer-links {
    color: white;
    margin: 4px;
    width: 90px;
  }

  .IonCardWrap {
    padding: 25px 15px 25px 15px !important;
    /* position: fixed;
    bottom: 60px;
    left: -7px;
    right: 0; */
    width: auto;
    border: 3px solid #13b3e6;
    /* margin: 15px; */
  }

  .toolbar-title {
    margin-bottom: 60px;
  }

  .item-inner {
    padding-bottom: 0%;
  }

  .makeStyles-unorderedList-6 {
    padding: 10px 40px 10px 40px !important;
  }

  #main-card-stakkid-button-3 {
    background-color: #4ca8d8;
    color: white;
    border-radius: 20px;
    padding: 3px 15px;
    font-weight: bolder;
    font-size: 20px;
    /* padding: 1px 1px; */
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    justify-content: center;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 10px;
  }
}

/* .testing{
  color: red
} */