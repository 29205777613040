.rating-wrapper, .rate-wrapper {
  position: relative;
  display: inline-block;
}

.rating, .rate {
  color: #fcc201;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
}
.rating-wrapper::before,
.rating::before {
  font-family: 'FontAwesome';
  content: '\f005 \0020 \f005 \0020 \f005 \0020 \f005 \0020 \f005';
}

.rate-wrapper::before,
.rate::before {
  font-family: 'FontAwesome';
  content: '\f005 \0020';
}

.search-result-rating {
   border: 2px dashed blue; 
   display: 'inline-flex';
   align-self:   'center'; 
}