.circle-stack {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 73px;
    width: 580px; /* Adjust the width as needed */
  }
  
  .circle {
    width: 150px;
height: 147px;
/* flex-shrink: 0; */
    background-color: #4B2B8E; /* You can set your desired background color */
    border-radius: 50%;
    margin-bottom: 70px; /* Adjust the margin between circles as needed */
  }

  .circle-content {
    text-align: center; /* Center text horizontally */
    font-weight: bold; /* Optional: Make the text bold */
    margin-top: 26px;

  }

  .circle-label {
    display: flex;
width: 157px;
height: 90px;
flex-direction: column;
justify-content: center;
flex-shrink: 0;
    color: #F6635C;
    font-family: DM Sans;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 50% */
  }
  
  .circle-value {
    display: flex;
width: 196px;
height: 26px;
flex-direction: column;
justify-content: center;
flex-shrink: 0;
color: #12022F;
font-family: DM Sans;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 155.556% */
margin-top: 37px;
margin-left: -22px;
  }
  