ul.MuiList-root.MuiList-padding{
  display: flex;
  flex-wrap: wrap;
}
li.MuiListItem-gutters{
  width: 50%;
}
.IonCardWrap{
  margin-top: 0px;
}
#save_btn .button-native {
  background: '#911af8';
  border-radius: 30px;
}
.page-padding{
  padding-top: 0px;
}
.item-lines-none{
  --min-height: 37.5px;
}
.item-interactive{
  --min-height: 38px;
}
@media (max-width: 500px) {
  .ass-card .makeStyles-root-9{
    min-width: 160px;
  }
  #add-more{
    min-width: 160px !important;
  }
}
@media (max-width: 400px) {
  .ass-card .makeStyles-root-9, #add-more {
    min-width: 120px !important;
  }

}