.circles {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.circle {
  display: flex;
  width: 120px;
  height: 120px;
  background-color: #f64646;
  border-radius: 50%;
}

.circle2 {
  display: flex;
  width: 120px;
  height: 120px;
  background-color: #ecbf2f;
  border-radius: 50%;
}

.circle3 {
  display: flex;
  width: 120px;
  height: 120px;
  background-color: #2a428c;
  border-radius: 50%;
}

.circle4 {
  display: flex;
  width: 120px;
  height: 120px;
  background-color: #75aadb;
  border-radius: 50%;
}

.circle5 {
  display: flex;
  width: 120px;
  height: 120px;
  background-color: #6d4d95;
  border-radius: 50%;
}

.circle6 {
  display: flex;
  width: 120px;
  height: 120px;
  background-color: #4ec251;
  border-radius: 50%;
}

.text {
  margin: auto;
  color: white;
  text-align: center;
}
