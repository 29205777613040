.clientPage {
    /* display: flex; */
    /* width: 1440px; */
    max-width: 1440px;
    min-height: 1920px;
    padding: 36.09px 338px 1841.91px 200px;

    position: relative;
    /* width: auto; */
/* max-width: 1440px;
    min-height: 1920px; */

    /* height: 100vh; For 100% screen height */
    /* width:  100vw; For 100% screen width;  */
    margin: auto;


    /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* flex-direction: column; */


}

.header2 {
    /* position: absolute;
    width: 1000px;
    height: 42px;
    left: 405px;
    top: 37px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 110%;

    display: flex;
    letter-spacing: -0.02em;


    color: #212121; */
    right: -38.5px;

    width: 1000px;
    color: #212121;
    font-family: DM Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 41.8px; /* 87.083% */
    letter-spacing: -0.76px;


}

.para1 {
    /* position: absolute;
    width: 1325px;
    height: 56px;
    left: 481px;
    top: 215.609px;

    font-family: 'DM Sans';
    font-style: normal;
font-weight: 700;
    font-size: 18px;
    line-height: 26.4px; 


    display: flex;
    align-items: center;
    text-align: center;

    color: #6D4D95; */
    width: 1325px;
    /* position: absolute; */
right: -37.5px;
top: 230.609px;
padding-bottom: 5px;
color: #666677;
font-family: DM Sans;
font-size: 24px;
font-style: normal;
font-weight: 200;
line-height: 26.4px; /* 110% */
letter-spacing: -0.48px;

}

.para-img {
    width: 1047px;
}

.para2 {
    /* position: absolute;
    width: 1250px;
    height: 469px;
    left: 124px;
    top: 440px;

    background: #FBFBFB;
    border-radius: 16px; */

    width: 1047px;
height: 525px;
/* position: absolute; */
left: 153.5px;
margin-top: -20px;
bottom: -90px;
/* border-radius: 16px; */
background: #F4F4F4;
}


.card-para-one {
    display: flex;
    flex-shrink: 0;


width: 407px;
padding: 30px 0px 0px 50px;
align-items: left;
color: #667;
font-family: DM Sans;
font-size: 30px;
font-style: normal;
font-weight: 400;
line-height: 37.5px;
}


.para-image {
    /* left: 153.5px; */

    margin-top: 30px;
    width: 1087px;
    height: 525px;
}

.para-laptop {
    position: absolute;
left: 9.45%;
right: 9.55%;
top: 0%;
bottom: 5.04%;

background: #8B67B8;
}

.para-left {
    position: absolute;
    max-width: 532px;
    max-height: 612px;
    left: 0px;
    top: 24px;
    
    background: #FBFBFB;
    border-radius: 16px;
}

.para-right {
    position: absolute;
    width: 1030px;
    height: 612px;
    left: 734px;
    top: 74px;

    /* background: #FCFCFC; */
    border-radius: 16px;
}

.para-title {
    color: #212121;
    text-align: center;
    font-family: DM Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 106.4px; /* 55% */
    letter-spacing: -0.48px;
}

.para-group {
    width: 486px;
    height: 340px;
    flex-shrink: 0;
    margin-left: 55%;
    margin-top: -11.5%;
    
    letter-spacing: -0.48px;


}

ul {
    list-style: none; /* Remove default bullets */
  }
  
 

.para-one {
    display: flex;
width: 469px;
padding: 10px 39px 0px 0px;
align-items: flex-start;
gap: 11px;
    color: #757575;
font-family: DM Sans;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 25px; /* 155.556% */
}

.para-two {
    display: flex;
width: 490px;
padding: 30px 151px 2px 0px;
align-items: flex-start;
gap: 11px;
    color: #757575;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 155.556% */
}

.para-three {
    display: flex;
width: 460px;
padding: 30px 10px 0px 0px;
align-items: flex-start;
gap: 11px;
    color: #757575;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 155.556% */
}

.para-four {
    display: flex;
width: 461px;
height: 108px;
padding: 40px 23px 30px 0px;
align-items: flex-start;
gap: 11px;
flex-shrink: 0;
    color: #757575;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 155.556% */
}



.card-one {
    position: absolute;
width: 400px;
height: 335px;
left: 125px;
top: 90px;



background: #FCFCFC;
border-radius: 16px;
}

.card-one-title {
    /* width: 238px; */
    height: 26px;

padding-top: 60px;

    padding-left: 90px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    /* or 26px */
    
    display: flex;
    align-items: center;
    text-align: center;

    letter-spacing: -0.02em;
    
    /* systemGrey/900 */
    
    color: #212121;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}

.card-one-para {
    width: 369px;
    height: 112px;

padding-top: 60px;
    padding-left: 50px;

    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    text-align: center;
    
    color: #757575;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    flex-grow: 0;
}

.card-one-button {
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 26px;
gap: 12px;

position: absolute;
width: 150px;
height: 56px;
left: 135px;
top: 230px;
color: #FFFFFF;
font-weight: bolder;
background: #AEA7FF;
border-radius: 5px;

}

.card-two {

position: absolute;
width: 400px;
height: 335px;
left: 549px;
top: 90px;

background: #FCFCFC;
border-radius: 16px;
}

.card-two-title {
    /* width: 231px; */
    height: 26px;

    padding-top: 60px;
    padding-left: 90px;

    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    /* or 26px */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    /* systemGrey/900 */
    
    color: #212121;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}

.card-two-para {
    width: 369px;
    height: 112px;
    padding-top: 60px;
    padding-left: 50px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    text-align: center;
    
    color: #757575;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    flex-grow: 0;
}

.card-two-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 26px;
    gap: 12px;
    
    position: absolute;
    width: 150px;
    height: 56px;
    left: 135px;
    top: 230px;
color: #FFFFFF;
font-weight: bolder;

background: #AEA7FF;
    border-radius: 5px;
}

.card-three {
    position: absolute;
    width: 400px;
    height: 335px;
    left: 973px;
    top: 90px;
    
    background: #FCFCFC;
    border-radius: 16px;
}

.card-three-title {
    /* width: 124px; */
    height: 26px;

    padding-top: 60px;

    padding-left: 140px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    /* or 26px */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    /* systemGrey/900 */
    
    color: #212121;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}

.card-three-para {
    width: 369px;
    height: 140px;

    padding-top: 30px;
    padding-left: 50px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    text-align: center;
    
    color: #757575;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    flex-grow: 0;
}

.card-three-button {
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 26px;
gap: 12px;

position: absolute;
width: 150px;
height: 56px;
left: 135px;
    top: 230px;
color: #FFFFFF;
font-family: 'DM Sans';
font-style: normal;
font-weight: 900;
background: #AEA7FF;

border-radius: 5px;
}

/* .rectangle-one {
    position: absolute;
width: 1254px;
height: 284px;
left: 120px;
top: 930px;
padding-bottom: 30px;
border-radius: 5px;
background: #7F59AE; */

/* background: linear-gradient(180deg, #875EB9 -9.54%, rgba(109, 77, 149, 0) 93.32%); */
/* } */

/* .rectangle-title {
    position: absolute;
    width: 189px;
    height: 42px;
    left: 105px;
    top: 77px;
    bottom: 0px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 110%; */
    /* identical to box height, or 42px */
    
    /* display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #FFFFFF;
    
} */

/* .rectangle-para {
    position: absolute;
    width: 789px;
    height: 56px;
    left: 105px;
    top: 151px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px; */
    /* or 156% */
    
    /* display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
} */

.rectangle-button-one {
    text-transform: uppercase;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 26px;
    gap: 12px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 900;
    /* position: absolute; */
    width: 250px;
    height: 56px;
    margin-left: 47px;
    margin-top: -200px;
    color:#ffffff;
    background: #7F59AE;
    border-radius: 30px;
}

@media only screen and (max-device-width: 844px) {


    .rectangle-button-one {
     
          width: 250px;
        height: 56px;
        margin-left: 58px;
        margin-top: 51px;
        color:#ffffff;
        background: #7F59AE;
        border-radius: 30px;
    }

    /* .clientPage-1 {
      
    display: none;
    
    } */

    .card-para-one {
        display: flex;
    flex-shrink: 0;
    width: 356px;
    padding: 47px 0px 0px 13px;
    align-items: left;
    color: #667;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 37.5px;
    }

    .clientPage {
        position: relative;
        /* width: auto; */
        /* max-width: none; */
        margin: auto;
    
        /* height: 100vh; For 100% screen height */
        width:  100vw; 
       
    
    
    }

    .para1 {
        left: 13px !important;
        top: 34px !important;
        width: 377px;
        position: absolute;
        right: -37.5px;
        top: 230.609px;
        padding-bottom: 5px;
        color: #666677;
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 26.4px;
        letter-spacing: -0.48px;
    }

    .header2 {
        position: relative;
        width: 692px;
        height: 42px;
        left: -186px;
        top: -31px;
    
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 110%;
        /* identical to box height, or 42px */
    
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
    
        /* systemGrey/900 */
    
        color: #212121;
    
    }
    
    .card-one {
        position: absolute;
    width: 400px;
    height: 335px;
    left: -10px;
    top: 90px;
    
    
    
    background: #FCFCFC;
    border-radius: 16px;
    }

    .card-two {

        position: absolute;
        width: 400px;
        height: 335px;
        left: -10px;
        top: 450px;
        
        background: #FCFCFC;
        border-radius: 16px;
        }

        .card-three {
            position: absolute;
            width: 400px;
            height: 335px;
            left: -10px;
            top: 820px;
            
            background: #FCFCFC;
            border-radius: 16px;
        }


.para2 {
    position: absolute;
    width: 360px;
    height: 469px;
    left: 10px;
    top: 231px;

    background: #FBFBFB;
    border-radius: 16px;
}

.rectangle-one {
    position: absolute;
    width: 360px;
height: 184px;
left: 18px;
top: 1730px;
padding-bottom: 30px;
border-radius: 5px;
background: #7F59AE;

/* background: linear-gradient(180deg, #875EB9 -9.54%, rgba(109, 77, 149, 0) 93.32%); */
}

.para-image {
    position: absolute;
width: 352px !important;
max-height: 176px;
left: 10px;
top: 36px;

background: #bcbcbc;
border-radius: 16px;
}


.para-img {
    position: absolute;
width: 352px !important;
/* max-height: 612px; */
left: 0px;
top: 0px;

background: #FBFBFB;
border-radius: 16px;
}

.rectangle-title {
    position: absolute;
    width: 189px;
    height: 42px;
    left: 20px;
    top: 27px;
    bottom: 0px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 110%;
    /* identical to box height, or 42px */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #FFFFFF;
    
}

.rectangle-para {
    position: absolute;
    width: 350px;
    height: 56px;
    left: 20px;
    top: 91px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
}

.rectangle-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 26px;
    gap: 12px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 900;
    position: absolute;
    width: 140px;
    height: 56px;
    left: 205px;
    top: 18px;
    font-size: 10px;

    color:#7F59AE;
    background: #FFFFFF;
    border-radius: 5px;
}


.para-right {
    position: absolute;
    width: 200px;
    height: 612px;
    left: 15px;
    top: 294px;

    /* background: #FCFCFC; */
    border-radius: 16px;
}


.para-title {
    position: absolute;
    width: 337px;
    height: 26px;
    left: 11px;
    top: 14px;
    padding-bottom: -14px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 110%;
    /* or 26px */

    /* display: flex;
    align-items: center; */
    letter-spacing: -0.02em;

    color: #6D4D95;
}

.para-group {
    padding-top: 100px;
    margin-left: 5%;
    height: 312px;

   }



.para-one {
    width: 337px;
    height: 56px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 28px;
    /* or 156% */
    padding-top: 10px;

padding-bottom: 0px;
    /* display: flex;
    align-items: center; */

    color: #757575;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.para-two {
    width: 337px;
    height: 28px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 28px;
    /* identical to box height, or 156% */

    padding-top: 10px;

    padding-bottom: 30px;

    /* display: flex;
    align-items: center; */

    color: #757575;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.para-three {
    width: 337px;
    height: 56px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 28px;
    /* or 156% */

    padding-top: 15px;

    padding-bottom: 0px;

    /* display: flex;
    align-items: center; */

    color: #757575;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.para-four {
    width: 337px;
    /* height: 56px; */

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 28px;
    /* or 156% */

    padding-top: 0px;

    padding-bottom: 30px;

    /* display: flex;
    align-items: center; */

    color: #757575;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}


}