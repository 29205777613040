@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Montserrat', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Montserrat, Roboto, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}


.Chart-02 {
  margin-top: 3rem;
}
.Chart-01 {
  overflow: auto;
  width: 500px;
  display: inline-block;
  vertical-align: top;
  padding-bottom: 3rem;
}
.Chart-01 .recharts-wrapper {
  width: 500px;
  height: 200px;
}

.terms-button {
  background: #0a015e;
  border-color: #0a015e;
  border-radius: 4px;
  padding: 3px 15px;
  line-height: normal;
  font-weight: 700;
  font-size: 1rem;
  color: #fff;
  border: 1px solid transparent;
  white-space: nowrap;
  position: relative;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  height: 42px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body > iframe {
  pointer-events: none;
}

/* body {
  width: 1920px;
} */

body {
  font-family: Montserrat, Arial;
}
.page-footer {
  z-index: 5
}

.nav-stack {
  display: flex;
  justify-content: center;
  

  /* padding-left: 30px; */
}

.form-check-label {
  padding-left: 10px;
}

.terms-message {
  padding-left: 10%;
}

.Chart-01 {
  /* margin-left: 50px; */
}

.Chart-02 {
  /* margin-right: 40px; */
}

.Chart-03 {
  /* margin-right: 40px; */
}



.score-title {
  max-width: 15%;
  margin-top: -7px;
}
.Sign-Google {
  text-align: center;
  margin-left: 20%;
}
.socialLinks {
  position: fixed !important;
}

.float-container {
  padding: 20px;
}

.float-child {
  width: 30%;
  float: left;
  /* padding: 20px; */
}

.float-childd {
  min-width: 30%;
  float: right;
  /* padding: 20px; */
  text-align: right;
  font-size: 32px;
  color: black;
  /* padding-left: 10px; */
}

.certt {
  position: relative;
  text-align: center;

  object-fit: cover;
  line-height: normal;

  /* flex-direction: column; */
}

.cert {
  display: flex;
  text-align: center;
  margin-left: 20%;

  position: relative;
  text-align: center;

  object-fit: cover;
  line-height: normal;

  /* flex-direction: column; */
}

.certName {
  position: absolute;
  text-align: center;
  background-color: transparent;
  padding-top: 10px;
  top: 50%;
  right: 44%;
}

.certScore {
  position: absolute;
  text-align: center;
  background-color: transparent;

  padding-top: 10px;
  top: 61%;
  right: 46%;
}

.socialMediaPopper {
  top: 19% !important;
  left: 50% !important;
  right: 50% !important;
  display: flex;
  /* position: sticky !important; */
  text-align: center;
  margin-left: 46%;
  padding-top: 10px;
  padding-bottom: 10px;

  /* flex-direction: column; */
}

.socialMediaButtons {
  top: 19% !important;
  left: 50% !important;
  right: 50% !important;
  display: flex;
  position: -webkit-sticky !important;
  position: sticky !important;
  text-align: center;
  margin-left: 43%;
  padding-top: 10px;
  padding-bottom: 10px;

  /* flex-direction: column; */
}

.socialMediaPoppers-2 {
  /* top: 19% !important; */
  /* left: 50% !important; */
  /* right: 50% !important; */
  display: flex;
  position: -webkit-sticky !important;
  position: sticky !important;
  text-align: center;
  margin-left: 41%;
  padding-top: 10px;
  padding-bottom: 10px;

  /* flex-direction: column; */
}

.socialMediaPoppers {
  top: 19% !important;
  left: 50% !important;
  right: 50% !important;
  display: flex;
  position: -webkit-sticky !important;
  position: sticky !important;
  text-align: center;
  margin-left: 52.5%;
  padding-top: 10px;
  padding-bottom: 10px;

  /* flex-direction: column; */
}

.socialMediaButton {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.socialShare {
  margin-top: 7px;
  margin-right: 20px;
  color: #000;
  /* padding-left: 25px; */
}

/* .socialMediaButton:hover > svg {
  height: 60px !important;
  width: 60px !important;
} */

.social-buttonn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
}
.social-buttonn1 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}

.social-button1 {
  margin-left: 5px;
}

.social-button2 {
  margin-left: 30px;
  border-radius: 10%;
}

#main-stakkid-button {
  background-color: #6d4d95;

}

#main-stakkid-button-log {
  background-color: #6d4d95;
  border-radius: 20px;
  border: none;
  height: 45px;
  width: 190px;
  font-size: 18px;
  margin: 10px;


  



}

.modalContainer2  {
  flex: 50% 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer2 .footer2 {
  flex: 20% 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer2 .footer2 button {
  width: 190px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
}

#cancelBtn2 {
  background-color: #6d4d95;
  border-radius: 20px;


}

.titleCloseBtn2 {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;

  padding-right: 15px;
}

.titleCloseBtn2 button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
  
}

.signin-1 {
  /* font-weight: bolder; */
  text-align: center;

  margin-left: 20%;
  padding-top: 5%;
  padding-bottom: 10%;
}

.signin {
  /* font-weight: bold; */
  text-align: center;

  margin-left: 25%;
  padding-top: 5%;
  padding-bottom: 10%;
}

.signi {
  /* font-weight: bolder; */

}

#main-landing-photo-container {
  /* background-color: #18355f; */
  /* width: 1024px; */
  /* height: 575px; */
  /* display: block; */
  object-fit: cover;
  width: 100%;
  /* position: fixed; */
  height: 100%;
  margin-bottom: 30px;

  margin-left: auto;
  margin-right: auto;
}

#card-styles:hover {
  box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.253) !important;
}

/* .middle-logo {
  margin-left: -11px;
} */

.toolandcat {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  margin-left: 20px;
}

.home-vdo-thumbnaill {
  background-color: #13b3e6;
  /* background-image: url(../assets/images/Screen\ Shot\ 2021-11-22\ at\ 12.11.41\ PM.png); */
  /* background-position: center;
  height: 12em;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; */
  width: auto;
  height: auto;
  /* position: absolute; */
  /* top: 550px; */
  align-items: center;

  /* right: 0; */
  /* left: 400px; */
  /* bottom: auto; */
  /* z-index: 111; */
  /* padding: 21px 21px 0; */
  /* border-radius: 2px; */
  padding: 5px 13px;
  border: none;
}

.home-vdo-thumbnailll {
  background-color: #13b3e6;
  /* background-image: url(../assets/images/Screen\ Shot\ 2021-11-22\ at\ 12.11.41\ PM.png); */
  /* background-position: center;
  height: 12em;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; */
  width: auto;
  height: auto;
  border: none;

  position: absolute;
  top: 0px;

  right: 0;
  /* left: 400px; */
  bottom: auto;
  /* z-index: 111; */
  /* padding: 21px 21px 0; */
  /* border-radius: 0px; */
  /* padding: 5px 13px; */
}

.wrapperr {
  width: 100%;
  display: flex;
  justify-content: center;

  /* height: 400px; */
  /* border: 1px solid black; */
}

.nav-buttonn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  position: relative;
  margin: -20px -50px;
  width: 170px;
  top: 50%;
  /* left: 50%; */
  /* right: 25%; */
  background-color: #6d4d95;
  border: hidden;
  border-radius: 30px;
}

.home-fonttt {
  color: white;
  /* text-align: center; */
  font-weight: 900;
  font-family: 'Nunito Sans', sans-serif;

  /* margin-bottom: 15px; */
  padding: 4px;
  padding-right: 10px;
  line-height: normal;
  font-size: 100%;
}

.home-substanceee {
  display: flex;
  flex-direction: row;
  justify-content: center;

  /* align-items: center; */
}

/* .header-containerr {
  box-sizing: border-box;
} */

.home-substancee {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* text-align: center; */
}
.align-middle {
  padding-right: 10px;
}

.home-fontt {
  color: rgb(0, 0, 0);
  align-items: center;
  font-weight: 900;
  margin-bottom: 7px;
  padding: 1px;
  padding-right: 10px;
  padding-left: 10px;

  line-height: normal;
  font-size: 100%;
}
i.fa.fa-youtube-play {
  font-size: 85px;
  color: #f70101;
}
i.fa.fa-youtube-play:hover,
i.fa.fa-youtube-play:active {
  color: red;
}

@media (max-width: 1024px) {
  .home-vdo-thumbnaill {
    display: none;
  }

  #main-navbar-container-2 {
    background-color: #1b3a22;
    /* margin-bottom: -35px; */
    height: 156px;
  }

}


@media (max-width: 1238px) {

  #navbar-links {
  
    font-size: 19px;
   
  }
}




@media (min-width: 1366px) {
  .home-vdo-thumbnailll {
    display: none;
  }

 
}

h1,
h2,
h3 {
  font-family: 'Nunito Sans', sans-serif;
}

#navbar-links {
  color: #6d4d95;
  margin: 8px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  clear: both;
}

#signing-links {
  color: white;
  margin: 8px;
  clear: both;
}

#signing-links:hover {
  background: white;
  color: #18355f;
}

#main-navbar-container {
  background-color: #e5e5e5;
  /* position: fixed; */
  margin-top: 10px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
}



.navbar-nav {
  background-color: #ffffff;
  position: relative;
  z-index: 999;
}

#main-navbar-container-2 {
  /* background-color: #ffffff; */
  background: rgba(255, 255, 255, 1);
  /* margin-bottom: -35px; */
  height: 80px;
}

#main-navbar-container-3 {
  background-color: #e5e5e5;

  /* margin-bottom: -35px; */
  height: 51px;
}

#stakkid-nav-logo {
  margin-bottom: 8px;
  width: 135px;
}

#stakkid-nav-logo-footer {
  margin-top: 10px;
  width: 135px;
}

#stakkid-nav-logo-2 {
  margin-left: 11px;
  margin-bottom: 25px;
  width: 150px;
}

#choose-martech-tools-container {
  background-color: #18355f;
  /* height: 590px; */
  width: 100%;
  margin: 0;
  overflow: hidden;
}

#footer-links {
  color: #43498f;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 15px;
  margin: 8px;
  margin-top: 18px;
}

#footer-links-contact {
  color: #43498f;
  font-family: 'Nunito Sans', sans-serif;
  /* font-size: 15px; */
  margin: 8px;
  margin-top: 18px;
}

#stakkid-footer-link {
  color: white;
  margin: 8px 9%;
}

#stakkid-logo-for-text {
  width: 100px;
  height: 100px;
  margin-bottom: 5px;
}

/* .img-row {
  display: flex;
} */

.img-fluid2 {
  flex: 50% 1;
  padding: 5px;
}

.IonCardWrap {
  margin-top: -10%;
}

#main-landing-photo-container {
  /* background-color: #18355f; */
  /* width: 1024px; */
  /* height: 575px; */
  /* display: block; */
  object-fit: cover;

  margin-left: auto;
  margin-right: auto;
}

#main-landing-bottom-photo {
  width: 1024px;
  height: 575px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* #martech-tools-begin-button {
  background-color: rgb(10, 1, 94);
  height: 70px;
  width: 175px;
  color: white;
  font-size: larger;
  margin: 10%;
} */

#choose-martech-tools-rightsplit {
  text-align: center;
  float: right;
  padding-bottom: 20px;
}

#choose-martech-tools-leftsplit {
  text-align: center;
  float: left;
  padding-bottom: 20px;
}

.user-email {
  font-family: 'Nunito Sans', sans-serif;
  font-color: #6d4d95;
}

#main-card-stakkid-button {
  background-color: #6d4d95;
  color: white;
  border-radius: 30px;
  padding: 15px 15px;
  border: none;
  
}
#main-card-stakkid-button-2 {
  background-color: #6d4d95;
  color: white;
  border-radius: 20px;
  padding: 5px 15px;
  font-weight: bold;
  border: none;
  font-size: 17px;
  font-family: 'Nunito Sans', sans-serif;

  /* display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  align-items: inherit; */
}

.main-card-stakkid-button {
  background-color: #6d4d95;
  color: white;
  border-radius: 30px;
  padding: 3px 15px;
  font-weight: bold;
  font-size: 20px;
  
}

.main-card-stakkid-buttons {
 
  display: flex;
  justify-content: center;
}

/* .input-wrapper {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  align-items: inherit;
} */

.addToolPopover {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#main-card-center {
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 28%;
  left: 34%;
   
  


}

#main-card-stakkid-button-3-1 {
  background-color: #6d4d95;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 3px 15px;
  font-weight: bolder;
  font-size: 20px;
  /* padding: 1px 1px; */
  min-width: 227px;
  display: flex;
  flex: 1 1;
  /* flex-direction: column; */
  justify-content: center;
  margin-left: 60.2%;
  margin-right: 45%;
  margin-bottom: 5px;
}

#main-card-stakkid-button-3-1-2 {
  background-color: #6d4d95;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 3px 15px;
  font-weight: bolder;
  font-size: 20px;
  /* padding: 1px 1px; */
  min-width: 227px;
  display: flex;
  flex: 1 1;
  /* flex-direction: column; */
  justify-content: center;
  margin-left: 60%;
  margin-right: 45%;
}

#main-card-stakkid-button-3 {
  background-color: #6d4d95;
  color: white;
  border: none;
  /* padding: 3px 15px; */
  font-weight: bolder;
  font-size: 20px;
  margin: 20px;

  /* padding: 1px 1px; */
  /* display: flex; */
  /* flex: 1 1 0%;
  flex-direction: column;
  justify-content: center; */
  /* margin-left: 63%;
  margin-right: 45%; */
}

#main-card-stakkid-button-5 {
  background-color: #6d4d95;
  color: white;
  border: none;
  /* padding: 3px 15px; */
  font-weight: bolder;
  font-size: 20px;
  /* padding: 1px 1px; */
  /* display: flex; */
  /* flex: 1 1 0%;
  flex-direction: column;
  justify-content: center; */
  /* margin-left: 63%;
  margin-right: 45%; */
}

.column {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#main-stakkid-button:focus {
  outline: 0;
  background-color: rgb(66, 133, 244);
}

#disabled-stakkid-button {
  background-color: lightgray;
  border-radius: 13px;
  padding: 3px 15px;
  margin: 10px;
}

#disabled-stakkid-button:focus {
  outline: 0;
}

#card-styles {
  padding: 20px;
  width: 18rem;
}

.page-padding {
  padding: 13% 8%;
  margin-bottom: 23%;

  /* text-align: justify; */
}

#profile-page-text {
  font-size: 17px;
  color: gray;
}

#profile-input {
  border-radius: 15px;
  width: 250px;
  height: 35px;
  margin: 0 3px;
  padding-left: 5px;
}

#profile-input:focus {
  outline: 0;
}

#profile-text {
  font-size: 18px;
  display: flex;
  justify-content: center;
}

#company-profile-dropdown {
  background-color: transparent;
  color: gray;
  margin-left: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 170px;
}

.company-profile-dropdown-arrow {
  background-color: gray;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#disabled-profile-button {
  background-color: transparent;
  border: none;
}

#disabled-profile-button:focus {
  outline: 0;
}

#license-blocks {
  border: 1px solid black;
  margin: 5px;
  padding: 10px;
}

#billing-float-margins {
  margin: 10px;
  float: left;
}

#billing-total-box {
  border: 1px solid black;
  width: 175px;
  height: 100px;
  margin: 0 auto;
}

#bundle-box {
  border: 1px solid black;
  height: 180px;
  width: 150px;
  padding: 10px;
  margin: 30px;
  text-align: center;
}

#bundle-box-text {
  float: left;
  margin-top: -10px;
  margin-left: 10px;
}

#create-account-modal {
  text-align: center;
}

/* #create-account-modal-2 {
  border-radius: 5px;
padding-left: 20px

} */

#linkedin-login-button {
  background-color: #f5f5f5;
  height: 45px;
  width: 100%;
  margin: 0;
  border-radius: 5px;
}
#please-note-container {
  background-color: lightgrey;
}

#about-page-card {
  margin: 10px;
}


#bold-fonte1 {
  /* font-weight: bold; */
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  justify-content: center;
  margin-top: 60px;

  
}

#bold-fonte-nav {
  /* font-weight: bold; */
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  justify-content: center;
  /* margin-top: 60px; */

  
}

#bold-fonte {
  /* font-weight: bold; */
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  justify-content: center;
  margin-top: 60px;

  
}

#bold-font {
  /* font-weight: bold; */
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  justify-content: center;
  margin-top: -30px;

  
}

#bold-fonteee {
  /* font-weight: bold; */
  /* font-family: 'Open Sans', sans-serif; */
  margin-top: -20px;
  

}

#bold-fontee {
  font-weight: bolder;
  /* color: #18355f; */
  font-size: 43px;
  /* padding-left: 1%; */
  padding-top: 10px;
  padding-bottom: 50px;
  /* width: 100px; */
  display: flex;
  justify-content: center;
}

.resource-title {
  padding-bottom: 1%;
  padding-right: 1%;
}

.community-title {
  padding-bottom: 0.5%;
  padding-right: 1%;
}

.page-head {
  display: flex;
  flex-direction: row;
}

#stakkid-influencers-photo {
  /* border-radius: 50%; */
  width: 85%;
  padding: 0px;
  align-self: center;
}

#stakkid-influencers-card {
  /* border-radius: 5%; */
  padding: 10px;
  margin-bottom: 10px;
  /* background-color: rgb(237, 237, 237); */

  width: 20rem;
}

#stakkid-influencers-title {
  /* padding: 0px; */
  width: 100%;
  background-color: #18355f;
  color: white;
  text-align: center;
}

#stakkid-community-search {
  width: 50%;
  height: 40px;
  border-radius: 23px;
  padding-left: 45px;
  background: no-repeat scroll 7px;
}

#stakkid-community-influencers-container {
  display: flex;
  flex-direction: row;
  /* width: 20rem; */
}

#stakkid-community-qa-container {
  /* margin-left: 0;
  margin-right: auto; */
  /* width: 50rem; */
  /* float: left; */
}

#resources-partners-header-container {
  border-bottom: 3px solid black;
  /* padding-left: 100px; */
  padding-top: 40px;
}

#resources-teb-image {
  width: 300px;
  height: 150px;
  float: left;
}

#learn-more-button {
  color: white;
  background-color: lightgreen;
}

#resources-learn-more-container {
  padding: 50px 100px;
  border-bottom: 2px solid gray;
}

#resources-individual-partner-container {
  padding: 50px;
}

#resources-individual-partner-photo {
  width: 115px;
  height: 115px;
  float: left;
}

/* #experts-bench-text-container {
  padding-left: 20px;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.addTooltipPopover .popover-content {
  padding: 20px;
}

.addTooltipPopover-2 {
  padding-right: 20px;
}

.secondary-assesment-bx .MuiAccordion-root {
  box-shadow: none;
}
.secondary-assesment-bx .MuiAccordion-root.last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}
.secondary-assesment-bx .MuiAccordion-root:before {
  background-color: rgba(0, 0, 0, 1);
  opacity: 1 !important;
  display: block !important;
  visibility: visible;
}
.secondary-assesment-bx .MuiAccordion-root.Mui-expanded:before {
  opacity: 1;
}
.secondary-assesment-bx .MuiAccordionDetails-root .ass-card {
  height: 100%;
  min-height: 300px;
  width: 340px;
}
.InputDiv {
  margin: 0 0 1rem;
}
.SignGoogle {
  padding-right: 0%;
  align-items: center;
  /* background: #fefefe;
  border: 1px solid #fefefe;
  padding: 0.5rem;
  color: #13b3e6;
  display: block;
  border-radius: 0.3rem; */
}
.InputDiv label {
  margin: 0 0 0.3rem;
  text-align: left;
  display: block;
  font-weight: 600;
}
.InputDiv input {
  border: 1px solid #e5e5f5;
  border-radius: 6px !important;
  margin: 0 !important;
  padding: 0 1.2rem !important;
  width: 100% !important;
  position: relative;
  background: #fff;
  height: 48px !important;
  display: flex;
  align-items: center;
}
.BtnWrap button {
  background: #0a015e;
  border-color: #0a015e;
  border-radius: 4px;
  padding: 0 1.5rem 0.2rem;
  line-height: normal;
  font-weight: 700;
  font-size: 1rem;
  color: #fff;
  border: 1px solid transparent;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;

}

.BtnWrapp button {
  background: transparent;
  border-color: #0a015e;
  border-radius: 4px;
  padding: 0 1.5rem 0.2rem;
  line-height: normal;
  font-weight: 700;
  font-size: 1rem;
  color: #fff;
  border: 1px solid transparent;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 0px;
}

.BtnWrappp button {
  background: transparent;
  border-color: #0a015e;
  border-radius: 4px;
  padding: 0 1.5rem 0.2rem;
  line-height: normal;
  /* font-weight: 700; */
  font-size: 1.7rem;
  color: #fff;
  border: 1px solid transparent;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 190px;
}

.modalTitle {
  align-items: center;
}
.modalTitle .modal-header {
  border: 0;
  padding: 0;
}

.my-score{
 border: 8px solid #911af9;
 width: 100px;
 height: 100px;
 border-radius: 50%;
 color: #fff;
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: #e84521;
 font-size: 40px;
 margin: 0px auto;
}

.progress-stepper{
  display: flex;
}


@media (max-width: 1200px) {
  .progress-stepper{
    display: block;
  }
}

@media (max-width: 767px) {
.ms-title {
  font-size: 4px;

}

  #card-styles {
    width: 100%;
  }
  #bar-plot {
    width: 200%;
    border-color: white;
    outline: white;
  }
  .page-padding {
    padding: 30px 15px;
    margin-bottom: 13%;
  }
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 24px;
  }
  .custom-stepper {
    padding: 0;
  }
  .custom-stepper span svg {
    color: rgb(204 232 242);
  }
  div.custom-stepper {
    padding: 0;
    flex-direction: column;
    position: relative;
    margin-bottom: 20px;
  }
  .custom-stepper > div {
    padding-bottom: 15px;
    padding-left: 0;
    z-index: 1;
  }
  .custom-stepper .MuiStepConnector-alternativeLabel {
    display: none;
  }
  .custom-stepper .MuiStepLabel-root.MuiStepLabel-alternativeLabel {
    align-items: center;
    flex-direction: initial;
  }
  .custom-stepper .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 0;
    padding-left: 10px;
  }
  .custom-stepper:after {
    position: absolute;
    content: ' ';
    height: calc(100% - 25px);
    background: #bdbdbd;
    width: 1px;
    left: 11px;
    top: 23px;
    z-index: 0;
  }
  .custom-stepper > div:last-child {
    padding-bottom: 0;
  }
  .custom-stepper .MuiStepIcon-root.MuiStepIcon-completed {
    fill: #e84521;
    background: #ffffff;
  }
  .custom-modal {
    top: 0 !important;
    width: 100% !important;
    padding: 15px;
    display: flex;
    align-items: center;
  }
  .custom-modal .MuiPaper-root {
    padding: 25px 15px 25px 15px !important;
    position: relative;
    max-width: 290px;
  }
  .custom-modal ul li:last-child {
    padding-bottom: 0;
  }
  .custom-modal button {
    padding: 0;
    min-width: 0;
    min-width: initial;
    position: absolute;
    right: 0;
    top: -30px;
    color: #fff;
  }
  .custom-modal h2 {
    font-size: 22px !important;
    margin-bottom: 18px;
    color: #18355f;
    width: 100%;
    text-align: center;
    font-weight: bold !important;
  }
  .custom-modal ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .custom-modal ul li {
    padding: 0 0 12px;
    position: relative;
    padding-left: 15px;
  }
  .custom-modal ul li h5 {
    font-size: 15px;
  }
  .custom-modal ul li:after {
    position: absolute;
    left: 0;
    top: 7px;
    background: #000;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    content: '';
  }
  .secondary-assesment-cs .MuiSvgIcon-root {
    font-size: 24px !important;
  }
  .secondary-assesment-cs .MuiTypography-body1 {
    width: 100%;
  }
  .secondary-assesment-cs .MuiGrid-spacing-xs-2 {
    margin: 0;
    width: 100%;
    margin-top: 0 !important;
  }
  .secondary-assesment-bx.secondary-assesment-cs .MuiAccordion-root {
    box-shadow: none;
    background: #eee;
    margin-bottom: 10px;
    padding: 0 0 0 10px;
    border-bottom: 0;
  }
  .secondary-assesment-bx.secondary-assesment-cs .MuiAccordion-root:before {
    opacity: 0 !important;
  }
  .secondary-assesment-cs .MuiAccordionSummary-content h2 {
    font-size: 16px;
    margin: 0;
  }
  .secondary-assesment-cs .MuiSvgIcon-root {
    font-size: 24px !important;
  }
  .secondary-assesment-bx.secondary-assesment-cs
    .MuiGrid-spacing-xs-2
    > .MuiGrid-item {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  .secondary-assesment-bx.secondary-assesment-cs
    .MuiAccordionDetails-root
    .ass-card {
    padding: 0 !important;
    min-height: 200px;
    width: 100%;
  }
  .secondary-assesment-cs .MuiAccordionDetails-root {
    padding-left: 0;
    padding-right: 8px;
  }
  .makeStyles-modal-6 {
    width: 100% !important;
    top: 0 !important;
    overflow: auto;
  }
  .footer-copyright {
    flex-wrap: wrap;
  }
  .row.card-container {
    margin: 0 0 25px !important;
  }
  .row.card-container .card-deck {
    width: calc(100% - 30px) !important;
  }
  /* .hydrated {
    display: block;
  } */
  .MuiPaper-root.ass-card {
    padding: 1rem !important;
  }
  .MuiListItem-gutters {
    padding: 10px 0;
  }
  .ios.card-content-ios {
    padding: 15px 10px 15px 0;
  }
  .sc-ion-searchbar-ios-h {
    padding: 0 0 15px;
  }
  .ios.title-default {
    padding: 10px 0 0;
    text-align: left;
  }
  ion-select.ios {
    max-width: 100%;
    padding: 0;
    width: 100%;
    position: relative;
    display: flex;
    vertical-align: top;
    align-items: center;
  }
  .select-icon-inner {
    left: 0;
  }
  :host(.button-full) .button-native {
    padding: 0.7rem 1rem;
    height: auto;
  }
  .recharts-wrapper {
    position: relative;
    cursor: default;
    width: 280px;
    height: 200px;
    overflow: visible;
    margin: 0 auto !important;
    padding: 0;
  }
  .recharts-wrapper svg {
    width: 50%;
    position: absolute;
    top: -60px;
  }
  .rv-xy-plot {
    width: 100% !important;
    height: 280px !important;
    -webkit-transform: scale(0.7) !important;
            transform: scale(0.7) !important;
    margin: -40px 0 30px 0 !important;
  }
  #resources-partners-header-container {
    border-bottom: 3px solid black;
    padding: 20px 10px 0;
  }
  td {
    display: block;
    width: 100%;
    padding: 0.5rem;
  }
  #resources-learn-more-container {
    padding: 15px;
    border-bottom: 2px solid gray;
  }
  table {
    width: 90%;
    margin: 0 auto;
  }
  #stakkid-community-influencers-container {
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }
  #stakkid-community-search {
    width: 100%;
    background-size: 20px;
    background-position: 15px 50%;
  }
  #main-card-stakkid-button {
    max-width: 80%;
    float: none !important;
    margin: 0 auto;
    display: block;
  }
  #stakkid-influencers-card {
    width: 100%;
  }
  ion-select {
    display: flex !important;
    width: 100%;
    min-width: 100%;
  }
}

@media (max-width: 420px) {
  .IonCardWrap {
    max-width: 400px;
    margin: 15px;
  }
}

@media (min-width: 1281px) {
  #main-navbar-container-3 {
    display: none;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
  .card-deck {
  }
}

/* iphone 8 */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
  /* Styles */
  .custom-modal .MuiPaper-root {
    padding: 15px 0px 15px 0px !important;
    position: relative;
    max-width: 400px;
    border: 3px solid #13b3e6;
  }

  .IonCardWrap {
    padding: 25px 15px 25px 15px !important;
    /* position: fixed;
    bottom: 60px;
    left: -7px;
    right: 0; */
    width: auto;
    border: 3px solid #13b3e6;
  }

  .toolbar-title {
    margin-bottom: 60px;
  }

  .item-inner {
    padding-bottom: 0%;
  }

  .makeStyles-unorderedList-6 {
    padding: 10px 40px 10px 40px;
  }
  .social-buttonn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .social-buttonn1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .social-button1 {
    margin-left: 70%;
    margin-bottom: 10px;
  }

  .social-button2 {
    margin-left: 70%;
  }
  .signin {
    margin-left: 20%;
  }
}

/* iphone 10 */
@media only screen and (min-device-width: 375px) and (max-device-width: 844px) {
  /* Styles */
  .ms-title {
    font-size: 34px !important;
  
  }

  .ms-title-2 {
    font-size: 24px !important;
  
  }
  .Appp-1 {
    display: none !important;

  }

 .text-center {
  font-size: 18px !important;

 }

 .software-stakkid-button-rate-1 {
  margin-right: 2% !important;
 }

 
  .custom-modal .MuiPaper-root {
    padding: 15px 0px 15px 0px !important;
    position: relative;
    height: 550px;
    max-width: 400px;
    border: 3px solid #13b3e6;
  }

  .custom-modal h5 {
    font-size: 18px !important;
    margin-bottom: 18px;
    color: #18355f;
    width: 100%;
    /* text-align: center; */
    font-weight: bold !important;
  }

  .custom-modal h6 {
    font-size: 13px !important;
    margin-bottom: 18px;
    padding: 10px;
    /* color: #18355f; */
    width: 100%;
    /* text-align: center; */
    /* font-weight: bold !important; */
  }

  .social-buttonn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .social-buttonn1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .social-button1 {
    margin-left: 50%;
    margin-bottom: 10px;
  }

  .social-button2 {
    margin-left: 50%;
  }
  .signin {
    margin-left: 15%;
  }

  #main-navbar-container-3 {
    background-color: #ffffff;
    margin-bottom: 30px;
    height: 51px;
  }

  .image {
    width: 340px;
  }

  #stakkid-nav-logo-footer {
    margin-bottom: 5px;
    width: 80px;
  }

  #footer-links {
    color: white;
    margin: 4px;
    width: 90px;
  }

  .IonCardWrap {
    padding: 25px 15px 25px 15px !important;
    /* position: fixed;
    bottom: 60px;
    left: -7px;
    right: 0; */
    width: auto;
    border: 3px solid #13b3e6;
    /* margin: 15px; */
  }

  .toolbar-title {
    margin-bottom: 60px;
  }

  .item-inner {
    padding-bottom: 0%;
  }

  .makeStyles-unorderedList-6 {
    padding: 10px 40px 10px 40px !important;
  }

  #main-card-stakkid-button-3 {
    background-color: #4ca8d8;
    color: white;
    border-radius: 20px;
    padding: 3px 15px;
    font-weight: bolder;
    font-size: 20px;
    /* padding: 1px 1px; */
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 10px;
  }
}

/* .testing{
  color: red
} */
.rv-xy-plot__axis--vertical text {
  font-size: 14px;
}

.rv-xy-plot__axis--horizontal text {
  font-size: 14px;
}

.rv-xy-plot {
  font-size: 14px;
  border-color: antiquewhite;
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
  #bar-plot {
    width: 10%;
  }
}

body {
  margin: 0;
}
.container {
  box-sizing: border-box;
  padding: 10px;
  width: 200px;
  height: 800px;
  /* padding-left: 200px; */
  background-color: #fff;
}

svg {
  overflow: visible;
}

.rv-xy-plot__grid-lines__line {
  stroke: gray;
  stroke-width: 1;
}

yAxis {
  color: #75aadb !important;
}

:root {
  --ion-color-primary: #008bc0;
  --ion-color-primary-rgb: 0, 139, 192;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #007aa9;
  --ion-color-primary-tint: #1a97c6;
}

.Chart-01 {
  align-self: flex-start;
  display: flex;
}

.Chart-02 {
  display: flex;
  justify-content: center;
  align-self: flex-start;
  margin: 20px;
}

.Chart-03 {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin: 20px;
}

.Dashboard-rear {
  display: flex;
}

.add-tool {
  text-align: right;
  margin: 20px;
  max-width: 80%;
}

/* .Chart {
  padding: 0.5em;
} */

@media only screen and (min-device-width: 390px) and (max-device-width: 844px) {
  /* Styles */

  .Dashboard-rear {
    display: grid;
  }

  .Chart-01 {
    align-self: flex-start;
    flex-direction: row;

    display: flex;
  }

  .Chart-02 {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-self: flex-start;
    margin: 20px;
  }

  .Chart-03 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px;
  }
}
.circles {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.circle {
  display: flex;
  width: 120px;
  height: 120px;
  background-color: #f64646;
  border-radius: 50%;
}

.circle2 {
  display: flex;
  width: 120px;
  height: 120px;
  background-color: #ecbf2f;
  border-radius: 50%;
}

.circle3 {
  display: flex;
  width: 120px;
  height: 120px;
  background-color: #2a428c;
  border-radius: 50%;
}

.circle4 {
  display: flex;
  width: 120px;
  height: 120px;
  background-color: #75aadb;
  border-radius: 50%;
}

.circle5 {
  display: flex;
  width: 120px;
  height: 120px;
  background-color: #6d4d95;
  border-radius: 50%;
}

.circle6 {
  display: flex;
  width: 120px;
  height: 120px;
  background-color: #4ec251;
  border-radius: 50%;
}

.text {
  margin: auto;
  color: white;
  text-align: center;
}

.rating-wrapper, .rate-wrapper {
  position: relative;
  display: inline-block;
}

.rating, .rate {
  color: #fcc201;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
}
.rating-wrapper::before,
.rating::before {
  font-family: 'FontAwesome';
  content: '\f005 \0020 \f005 \0020 \f005 \0020 \f005 \0020 \f005';
}

.rate-wrapper::before,
.rate::before {
  font-family: 'FontAwesome';
  content: '\f005 \0020';
}

.search-result-rating {
   border: 2px dashed blue; 
   display: 'inline-flex';
   align-self:   'center'; 
}
.addToolPopover {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#save_btn .button-native{
  background: '#911af8';
  border-radius: 30px;
}

.role3 {
  white-space: 'normal' !important
}

.alert-radio-label.sc-ion-alert-md {
  white-space: 'normal' !important

}




.home-vdo-thumbnail {
  background-color: #13b3e6;
  /* background-image: url(../assets/images/Screen\ Shot\ 2021-11-22\ at\ 12.11.41\ PM.png); */
  background-position: center;
  height: 10em;
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  top: 100px;

  position: static;
  /* top: 550px; */
  width: auto;

  /* right: auto; */
  /* left: 400px; */
  /* bottom: auto; */
  z-index: 511;
  padding: 21px 21px 0;
  /* border-radius: 5px 5px 5px 5px; */
  margin-bottom: 15px;
  /* border-bottom: 9px solid #18355f; */
}
/* footer text */

.slide-title-31 {
  max-width: 25%;
  text-align: center;

  position: relative;
  margin-top: -25%;
  margin-left: 7%;
}

.slide1 {
  position: relative;
  text-align: center;

  /* object-fit: cover; */
  /* height: 595px; */
}

.slide1-text {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.slide2 {
  position: relative;
  text-align: center;

  /* object-fit: cover; */
  /* height: 595px; */
}

.slide2-text {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.slide3 {
  position: relative;
  text-align: center;

  /* object-fit: cover; */
  /* height: 595px; */
}

.slide3-text {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

#show_bg {
  background-image: url(/static/media/AdobeStock_361099167-Recovered.30b84ba3.png);

  width: 100%;
  height: 630px;

  background-size: cover;
  color: white;
  background-position: center;
  position: static;
  /* object-fit: 50%; */
}

.background {
  background: url() repeat;
  border: 2px solid black;
}

.img-fluid {
  object-fit: cover;
  /* position: fixed; */
}

.transbox {
  position: absolute;
  text-align: center;
  width: 100%;
  /* border: 1px solid black; */
  /* opacity: 0.6; */
}

div.transbox h3 {
  margin-top: -30%;
  text-align: center;

  margin-left: -60%;
  font-size: 50px;
  font-family: 'Nunito Sans', sans-serif;
  /* font-weight: bolder; */
  color: #6d4d95;
}

/* hero css */
#main-landing-photo-containerr {
  object-fit: cover;
  width: 100%;
  /* height: 100%; */
  /* margin-bottom: 30px; */
  position: relative; /* z-index: -1; */
}

.statement {
  color: #fff;
  margin-left: 20%;
}

.footer-text {
  color: #fff;
}

.hero-container {
  /* background: url('/images/AdobeStock_222880408 (1).png') center center/cover no-repeat; */
  /* height: 100vh; */
  width: 100%;
  /* display: flex; */
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: -28%;
  margin-left: -45%;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }

  #show_bg {
    /* position: absolute; */
    /* height: 100%; */
    width: auto;

    /* height: 630px; */
    /* object-fit: cover; */
    /* background-size: cover; */
    /* color: white; */
    /* background-position: center; */
    /* position: static; */
    /* object-fit: 50%; */
  }

  .header-container {
    box-sizing: border-box;
  }

  .home-substance {
    text-align: center;
  }
}

.home-fontt {
  color: white;
  font-family: Montserrat, Arial;

  /* text-align: center; */
  font-weight: 900;
  margin-top: 3px;
  /* padding: 0; */
  /* padding-top: 23px; */
  line-height: normal;
  font-size: 100%;
}
i.fa.fa-youtube-play {
  font-size: 85px;
  color: #494949;
  margin-top: 2px;

  /* padding-bottom: 30px; */
}

.home-vdo-thumbnaill:hover,
i.fa.fa-youtube-play:hover,
i.fa.fa-youtube-play:active {
  color: red;
}

@media (max-width: 767px) {
  .home-vdo-thumbnail {
    display: none;
  }

  div.transbox h3 {
    text-align: center;
    margin: 2%;
    font-size: 20px;
    font-family: Roboto bold, Arial;

    font-weight: bolder;
    color: #ffffff;
  }
}

:root {
  --primary: #6d4d95;
}

.btn {
  padding: 8px 20px;
  border-radius: 30px;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.btn--primary {
  background-color: #6d4d95;
  background-color: var(--primary);
  color: #ffff;
  border: 1px solid #6d4d95;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #6d4d95;
  padding: 8px 20px;
  border: 1px solid #6d4d95;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
  margin: 6px;
  width: 250px !important;

}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #6d4d95;
  color: #ffffff;
  transition: 250ms;
}

i.fa.fa-youtube-play {
  margin-top: 1px;
}

.hero-btns {
  position: relative;

  top: 190px;
  right: -22%;

  /* text-align: center; */
}

.hero-bt {
  /* position: relative; */
}

.btns {
  position: absolute;

  font-family: 'Nunito Sans', sans-serif;
  /* left: -35px; */
}




.hero-btns-2 {
  position: relative;
  top: 30px;
  left: 0%;
  /* text-align: center; */
}

.btns-2 {
  position: absolute;
  /* padding: 8px 20px; */
  font-family: 'Nunito Sans', sans-serif;
  /* left: -35px; */
}

.hero-btns-3 {
  position: relative;

  top: 30px;
  left: 0%;
  /* text-align: center; */
}

.btns-3 {
  position: absolute;

  font-family: 'Nunito Sans', sans-serif;
  /* left: -35px; */
}

.slides {
  /* border-top: 6px solid #b61e4d; */
}

.hero-btns .btns {
  margin: 6px;
}
.hero-btns-2 .btns-2 {
  margin: 6px;
}
.hero-btns-3.btns-3 {
  margin: 6px;
}

.btns .fa-play-circle {
  margin-left: 4px;
}

.fa fa-youtube-play {
  top: 10px;
}

.slide-studying {
  /* opacity: 0.9; */
}

.slide-writing {
  /* opacity: 0.9; */
}

.slide-friends {
  /* opacity: 0.9; */
}

.slide1 {
  position: relative;
  text-align: center;

  object-fit: cover;
  height: auto;
  line-height: normal;
}


.slide1-text-right {
  position: absolute;
  top: 15%;
  left: 1%;
  color: #6d4d95;
  padding-left: 5px;
  padding-bottom: 72px !important;

  font-family: 'Nunito Sans', sans-serif;
  /* font-weight: bold; */
  font-size: 50px;
  /* line-height: normal; */
  line-height: 62px;
}

.rating-button {
background-color: #6d4d95;
color: white;
}

.slide-title-contain {
  /* display: flex; */
  align-items: center;
}


.slide1-text {
  position: absolute;
  top: 26%;
  /* right: -8%; */
  color: #6d4d95;
  /* padding-right: 15px; */
  /* padding-bottom: 72px !important; */

  font-family: 'Nunito Sans', sans-serif;
  /* font-weight: bold; */
  font-size: 50px;
  /* line-height: normal; */
  line-height: 42px;
}

.slide1-t-1 {
  /* font-weight: bold; */
  font-size: 50px;
  color: #6d4d95;
  /* margin: 0; */
  /* margin-bottom: 1%; */
  /* line-height: 2px; */
}

.slide1-t {
  /* font-weight: bold; */
  font-size: 50px;
  color: #6d4d95;
  /* margin-bottom: 1%; */
  /* line-height: 2px; */
}

.slide-title-1 {
  max-width: 60%;
  position: relative;
  padding-top: -15px;
  /* padding-right: 5px;
  padding-left: 5px; */
  padding-bottom: 47px;

  /* max-width: 100%; */
  height: auto;
  /* margin-right: 10px; */
  /* color: white; */

  /* margin-top: 50%; */
}

.slide1-text-ee {
  /* margin-bottom: 20px; */
  /* line-height: 2px; */
  /* font-weight: bold; */
  font-size: 50px;

  /* padding-bottom: 0; */
  color: #6d4d95;
}




.slide1-text-e {
  margin-bottom: 8px;
  /* line-height: 2px; */
  /* font-weight: bold; */
  font-size: 60px;

  /* padding-bottom: 0; */
  color: #6d4d95;
}

.slide1-tt {
  /* font-weight: bold; */
  font-size: 53px;
  color: #6d4d95;
  /* bottom: -15%; */
  line-height: 2px;
}

.slide1-text-p {
  margin-top: -18px;
  padding-top: 15px;
  font-size: 28px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 100;
  top: 10px;
  line-height: 1px;
}

.slide1-text-pp {
  display: none;
}



.slide2 {
  position: relative;
  text-align: center;
  height: 800px;

  object-fit: cover;
  /* height: 595px; */
  line-height: normal;
}

.slide2-t {
  font-weight: bold;
  font-size: 95px;
  color: #6d4d95;
  /* bottom: -15%; */
  line-height: 2px;
}

.slide2-text {
  position: absolute;
  top: 20%;
  left: -52%;
  color: #6d4d95;
  /* color: #cb0e60; */
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 72px !important;

  font-family: 'Nunito Sans', sans-serif;
  /* font-weight: bold; */
  font-size: 70px;
  /* line-height: normal; */
  line-height: 42px;
}



.slide2-text-e {
  /* margin-top: 12px; */
  /* line-height: 2px; */
  font-size: 55px;

  /* padding-bottom: 0; */
  color: #6d4d95;
}

.slide3-text-e {
  /* margin-top: 12px; */
  /* line-height: 2px; */
  font-size: 55px;

  /* padding-bottom: 0; */
  color: #6d4d95;
}

.slide2-text-p {
  margin-top: -12px;

  font-size: 25px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 100;
  /* top: 10px; */
  line-height: 1px;
}

.slide2-word {
  /* font-style: italic; */
  /* color: white; */
}

.slide3 {
  position: relative;
  text-align: center;
  height: 800px;

  /* object-fit: cover; */
  /* height: 595px; */
}

.slide3-text {
  position: absolute;
  top: 20%;
  left: -50%;
  color: #6d4d95;
  padding-left: 5px;
  padding-bottom: 72px !important;

  font-family: 'Nunito Sans', sans-serif;
  /* font-weight: bold; */
  font-size: 70px;
  /* line-height: normal; */
  line-height: 42px;
}

.slide-title {
  max-width: 15%;
  position: relative;
  top: 2px;
}

.slide-title-2 {
  max-width: 20%;
  position: relative;
  padding-top: 25px;
  padding-right: 5px;
  padding-left: 5px;

  color: white;

  /* margin-top: 50%; */
}

.slide-title-3 {
  max-width: 20%;
  position: relative;
  padding-top: 15px;
  padding-right: 5px;
  padding-left: 5px;

  color: white;

  /* margin-top: 50%; */
}

.slide3-text-p1 {
  font-weight: bold;
  font-size: 95px;
  color: #6d4d95;
  /* bottom: -15%; */
  line-height: 2px;
}

.slide3-text-p {
  margin-top: 35px;

  font-size: 25px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 100;
  /* top: 10px; */
  line-height: 1px;
}

/* .slide-title-3 {
  max-width: 15%;
  margin-right: 19px;
  bottom: 18%;
} */

@media screen and (max-width: 1141px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }

  .slide1-text-e {
    font-size: 48px;
    /* margin-top: -57px; */
    /* margin-left: 29%; */
  }

  .slide1-text {
    /* font-size: 48px; */
    /* margin-top: -57px; */
    margin-left: 59%;
  }

  .slide1-text-p {
    font-size: 25px;
    /* margin-top: -57px; */
    /* margin-left: 29%; */
  }

  .hero-btns {
    /* max-width: 65%; */
    /* display: none; */
    top: 130px;
    /* left: 62%; */
  }
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media only screen and (max-device-width: 844px) {
  .slide1-t-1 {
    /* font-weight: bold; */
    font-size: 30px;
    color: #6d4d95;
    width: 157px;
    /* margin: 0; */
    margin-bottom: 1%;
    line-height: 12px;
  }


  .slide1 {
    height: auto;
  }

  .slide1-t {
    font-size: 14px;
    /* margin-left: 10%; */
  }

  .slide1-text-ee {
    font-size: 23px;
    margin-top: -45px;
    /* margin-left: 30%; */
  }

  .slide1-text-e {
    font-size: 18px;
    margin-top: -77px;
    /* margin-left: 29%; */
  }
  .slide1-text-p {
    display: none;
  }
  .slide1-text-pp {
    display: block;
    font-size: 10px;
    margin-top: -70px;
    /* margin-bottom: -65px; */

    /* margin-left: 27%; */
    /* word-break: Keep all; */
  }
  .text-pp {
    margin-top: -10px;
    /* margin-bottom: -65px; */

    /* margin-left: 27%; */
    /* word-break: Keep all; */
  }

  .slide-title-1 {
    max-width: 15%;
    /* position: relative; */
    /* display: block; */
    padding-bottom: 20px;
    /* margin-top: -50px; */

    max-width: 60%;
    position: relative;
    /* padding-top: -15px; */
    /* padding-right: 5px;
    padding-left: 5px; */
    padding-bottom: 4px;
  
    /* max-width: 100%; */
    height: auto;
    /* margin-right: 10px; */
    /* color: white; */
  
    /* margin-top: 50%; */    
  }

  .hero-btns {
    max-width: 25%;
    display: none;
    top: 40px;
    left: 12%;
  }

  .hero-btns-2 {
    max-width: 25%;
    display: none;
    top: 70px;
    left: 62%;
  }

  .hero-btns-3 {
    max-width: 25%;
    display: none;
    top: 70px;
    left: 62%;
  }

  .slide2 {
    height: auto;
  }

  .slide2-t {
    font-size: 20px;
  }

  .slide2-text-e {
    font-size: 15px;
    margin-top: -60px;
  }
  .slide2-text-p {
    font-size: 11px;
    margin-top: -60px;
  }

  .slide3 {
    height: auto;
  }

  .slide3-text-p1 {
    font-size: 20px;
  }

  .slide3-text-e {
    font-size: 15px;
    margin-top: -60px;
  }
  .slide3-text-p {
    font-size: 11px;
    margin-top: -20px;
  }

  .slide-title-3 {
    padding-top: 1px;
    display: none !important;
  }

  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .slide1-text {
    top: 43px;
    padding-right: 2px;
    
  }

  .slide2-text {
    top: 57px;
    padding-left: 30px;
  }

  .slide3-text {
    top: 57px;
  }

  /* .slide2-text {
    font-size: 18px;
    top: 37px;
    padding-left: 30px;
  } */

  /* .slide2-text-p {
    font-size: 7.5px;
    padding-top: 10px;
    padding-left: -1px;
    padding-right: -1px;
  } */

  .slide-title-2 {
    display: none !important;
  }

  .slide-title-3 {
    padding-bottom: 5px;
  }

  /* .slide3-text {
    font-size: 18px;
    padding-top: 10px;
    top: 37px;
  }

  .slide3-text-p1 {
    font-size: 20px;
    padding-top: 13px;
  }

  .slide3-text-p {
    font-size: 10px;
    padding-top: 10px;
  } */

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}




.abtPage1{
    /* width: 1440px; */
    max-width: 1440px;
    /* max-width: 1440px; */

    /* width: 1486px; */
    min-height: 2392px;
    margin: auto;
    position: relative;

}

.abtheader-img {
    width: 1440px;
    /* height: 511px; */
    flex-shrink: 0; 
    background: rgba(0, 0, 0, 0.40);
    background-size: cover;

    /* margin-top: -8%; */
}

.circle-about1 {
    width: 153.091px;
    height: 475.21px;
    -webkit-transform: rotate(0.359deg);
            transform: rotate(0.359deg);
    flex-shrink: 0;
    background: url(/static/media/Dots.ce8d1aef.png), lightgray 50% / cover no-repeat;
    background-blend-mode: overlay;
    mix-blend-mode: overlay;
}

.circle-about2 {
    width: 150px;
    height: 147px;
    flex-shrink: 0;
    fill: #4B2B8E;
}

.clientPage1{
    /* width: 1440px; */
    max-width: 1440px;
    /* width: 1486px; */
    min-height: 1920px;
    margin: auto;
    position: relative;

}

.header-one {
    width: 1440px;
    height: 600px;
    flex-shrink: 0; 

}

.darkscreen {
    background: rgba(0, 0, 0, 0.40);
    position: absolute;
    height: 650px;
  width: 100%;
  /* top: 144px; */
  left: 0;
  /* background: rgba(0, 0, 0, 0.65); */
  /* color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;


}

.header-img {
    width: 1440px;
    /* height: 511px; */
    flex-shrink: 0; 
    background: rgba(0, 0, 0, 0.40);
    background-size: cover;

    /* margin-top: -8%; */
}

.header-text {
    position: relative;

    color: #ffffff;

    color: var(--white, #ffffff);
    font-family: Lato;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    width: 597px;
    margin-top: -444px;
    padding-left: 108px;
}

.body-one1 {
    width: 1450px;
height: 470px;
flex-shrink: 0;
overflow: hidden; /* add this to contain floated children */

}


.body-para {
    width: 706px;
    margin-top: 130px;
    margin-left: 3%;
    float:left; /* add this */


}

.para-body-img {
    /* float: left; */
    overflow: hidden; /* add this to contain floated children */
    margin-top: 63px;
    padding-left: 35px;


}


.body-img2 {
    width: 597px;
    height: 391px;
    flex-shrink: 0;

}

.para-one-body1 {
    display: flex;
width: 669px;
padding: 10px 39px 0px 0px;
align-items: flex-start;
grid-gap: 11px;
gap: 11px;
color: #646E73;
color: var(--greyish, #646E73);
    font-family: Lato;
    font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.para-two-body {
    display: flex;
width: 700px;
padding: 30px 151px 2px 0px;
align-items: flex-start;
grid-gap: 11px;
gap: 11px;
    color: #757575;
    font-family: DM Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 155.556% */
}

.para-three-body {
    display: flex;
width: 534px;
padding: 34px 10px 0px 0px;
align-items: flex-start;
grid-gap: 11px;
gap: 11px;
    color: #757575;
    font-family: DM Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 155.556% */
}

.para-four-body {
    display: flex;
width: 461px;
height: 108px;
padding: 40px 23px 30px 0px;
align-items: flex-start;
grid-gap: 11px;
gap: 11px;
flex-shrink: 0;
    color: #757575;
    font-family: DM Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 155.556% */
    /* margin-top: -26.5%; */

}

.para-title-body1 {
    width: 486px;
    /* color: var(--dark, #2D3748); */
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: -32px;

}

.para-group-body1 {
    width: 686px;
    height: 340px;
    flex-shrink: 0;
    /* padding-top: 5%; */
    /* margin-left: 55%; */
}


.body-two1 {
    width: 1450px;
    margin-left: 3%;

    overflow: hidden; /* will contain if #first is longer than #second */

}

.body-para-one1 {
    width: 669px;
    /* margin-top: 130px; */
    /* margin-left: -3%; */
    float:left; /* add this */
}

.body-h2-one {
    /* color: var(--dark, #2D3748); */
font-family: Lato;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
    
}

.body-p-one1 {
    color: #646E73;
    color: var(--greyish, #646E73);
font-family: Lato;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.body-para-two1 {
    overflow: hidden; /* if you don't want #second to wrap below #first */
    width: 669px;
    padding-left: 30px;
    padding-top: 44px;



}

.body-h2-two {
    /* color: var(--dark, #2D3748); */
font-family: Lato;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.body-p-two {
    color: #646E73;
    color: var(--greyish, #646E73);
font-family: Lato;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}


.rectangle-one1 {
    position: absolute;
    width: 653px;
    height: 503px;
    flex-shrink: 0;
left: 55px;
top: 1502px;
/* padding-top: 30%; */
border-radius: 5px;
background: #7F59AE;

/* background: linear-gradient(180deg, #875EB9 -9.54%, rgba(109, 77, 149, 0) 93.32%); */
}



.rectangle-title1 {
    position: absolute;
    width: 289px;
    height: 42px;
    left: 55px;
    top: 77px;
    bottom: 0px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 110%;
    /* identical to box height, or 42px */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #FFFFFF;
    
}

.rectangle-para1 {
    position: absolute;
    width: 540px;
    height: 56px;
    left: 55px;
    top: 151px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
}

.rectangle-title2 {
    position: absolute;
    width: 289px;
    height: 42px;
    left: 55px;
    top: 294px;
    bottom: 0px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 110%;
    /* identical to box height, or 42px */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #FFFFFF;
    
}

.rectangle-para2 {
    position: absolute;
    width: 789px;
    height: 56px;
    left: 55px;
    top: 340px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
}


.rectangle-para3 {
    position: absolute;
    width: 789px;
    height: 56px;
    left: 55px;
    top: 381px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
}

.rectangle-two1 {
    position: absolute;
    width: 653px;
    height: 503px;
    flex-shrink: 0;
left: 729px;
top: 1502px;
/* padding-top: 30%; */
border-radius: 5px;
background: #7F59AE;

/* background: linear-gradient(180deg, #875EB9 -9.54%, rgba(109, 77, 149, 0) 93.32%); */
}


.rectangle-title4 {
    position: absolute;
    width: 289px;
    height: 42px;
    left: 55px;
    top: 77px;
    bottom: 0px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 110%;
    /* identical to box height, or 42px */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #FFFFFF;
    
}

.rectangle-title5 {
    position: absolute;
    width: 289px;
    height: 42px;
    left: 211px;
    top: 161px;
    bottom: 0px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32.612px; /* 163.058% */    /* identical to box height, or 42px */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #FFFFFF;
    
}

.rectangle-para4 {
    position: absolute;
    width: 789px;
    height: 56px;
    left: 211px;
    top: 186px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15.613px;

    line-height: 31.226px; /* 200% */
    /* or 156% */
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
}


.rectangle-para5 {
    position: absolute;
    width: 789px;
    height: 56px;
    left: 211px;
    top: 217px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15.613px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
}

.rectangle-para6 {
    position: absolute;
    width: 497px;
    height: 201px;
    flex-direction: column;
    justify-content: center;
    left: 55px;
    top: 280px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
}

.body-img1 {
    position: absolute;

    width: 132px;
    height: 135px;
    left: 57px;
    top: 146px;
    flex-shrink: 0;
    border-radius: 92px;
border: 6px solid rgba(255, 255, 255, 0.23);
/* background: url(<path-to-image>), lightgray 0px -9.916px / 100% 121.557% no-repeat, #FFC130; */
}

.rectangle-button {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 26px;
    grid-gap: 12px;
    gap: 12px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 900;
    position: absolute;
    width: 250px;
    height: 56px;
    left: 980px;
    top: 151px;
    color:#7F59AE;
    background: #FFFFFF;
    border-radius: 30px;
}

.rectangle-button-o1 {
padding-top: 60px;
}



.rectangle-button-two1 {
    /* text-transform: uppercase; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 26px;
    grid-gap: 12px;
    gap: 12px;
    
    position: absolute;
    width: 669px;
    height: 56px;
    left: 381px;
    

    color: #646E73;
    

    color: var(--greyish, #646E73);
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.circle-stack {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 73px;
    width: 580px; /* Adjust the width as needed */
  }
  
  .circle {
    width: 150px;
height: 147px;
/* flex-shrink: 0; */
    background-color: #4B2B8E; /* You can set your desired background color */
    border-radius: 50%;
    margin-bottom: 70px; /* Adjust the margin between circles as needed */
  }

  .circle-content {
    text-align: center; /* Center text horizontally */
    font-weight: bold; /* Optional: Make the text bold */
    margin-top: 26px;

  }

  .circle-label {
    display: flex;
width: 157px;
height: 90px;
flex-direction: column;
justify-content: center;
flex-shrink: 0;
    color: #F6635C;
    font-family: DM Sans;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 50% */
  }
  
  .circle-value {
    display: flex;
width: 196px;
height: 26px;
flex-direction: column;
justify-content: center;
flex-shrink: 0;
color: #12022F;
font-family: DM Sans;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 155.556% */
margin-top: 37px;
margin-left: -22px;
  }
  
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.page-padding {
}

.wrapper {
    /* max-width: 1210px; */
    height: 433px;
flex-shrink: 0;
  }

  .rec-wrapper {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), lightgray 1016.001px -71.735px / 61.651% 128.197% no-repeat;
    height: 433px;
    margin-left: -5%;

    max-width: 1210px; 

    flex-shrink: 0;
  }
  
  .ppara {
    line-height: 1.6; 
    /* font-family: Helvetica; */
    text-align: justify;
    margin: 0;
    /* font-size: 14px; */
  }
  
  .star {
    float: right;
    width: 250px;
    shape-margin:20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  

.my-scoringone {
    height: 100%;

}

.ms-title {
    font-size: '58px';

}

.ms-title-2 {
    color: #212529;
font-family: DM Sans;
font-size: 47px;
font-style: normal;
font-weight: 900;
line-height: 57.6px; /* 122.553% */


}

.apppp {
    display: flex
}

.appp {
    padding: 20px;
    
}


.ms-title-2 {
    font-family: DM Sans;
    font-weight: 500;
    width: 989px;

  
  }

.myscore-para {
    padding-left: 20px;
    margin-top: -392px;
    width: 843px;
height: 310px;
flex-shrink: 0;
color: #000;
font-family: DM Sans;
font-style: normal;
/* font-weight: 400; */
line-height: 36.6px; /* 88.511% */
}




.appp-2 {
    width: 961px;
        height: 100%;
    /* padding-top: 10%; */
    /* padding-left: 30px; */
    color: #212529;
font-family: DM Sans;
font-size: 39px;
font-style: normal;
font-weight: 400;
line-height: 48px; /* 123.077% */
}

.appp-2-p {
    width: 1250px;
    color: #212529;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.5px; /* 150% */
}

.appp-2-p-1 {
    text-align: center;
    color: #212529;
font-family: DM Sans;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 22.5px; /* 150% */
padding-top: 10px;
padding-bottom: 10px;



}

.appp-2-p {
    font-size: '18px';

}

.img-fluid2 {
    width: 100%;
    border: 0.5px solid #e2e2e2;


}

.img-fluid3 {
    
    padding-left: 10%;

}


.img-row-title {
    color: #212529;
    font-family: DM Sans;
    font-size: 39px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    padding-left: 10%;

}

.img-row {
    width: 50%;
    float: left;
max-height: 639px;
flex-shrink: 0;
}



.img-row-2 {
    width: 570px;
    padding: 0px 186.53px 0px 186.52px;
    justify-content: center;
}


.purple-box {
        width: '600px';
        background-color: '#6d4d95';
        color: 'white';
        margin-left: '25%';
        padding: '5px';
}

.purple-para {
    font-size: '24px';
        
}

.purple-para-2 {
 
        
}

.beast-para-mobile {
    display: none;

}



.purple-para-mobile {
    display: none;
}

@media only screen   
and (min-width: 1030px)   
and (max-width: 1366px)  {


.beast-para-mobile {
    display: none;

}



.purple-para-mobile {
    display: none;
}

}

@media only screen   
and (min-device-width : 768px)   
and (max-device-width : 1024px)  {


.beast-para-mobile {
    display: none;

}



.purple-para-mobile {
    display: none;
}

}



@media only screen and (max-device-width: 844px) {

    .apppp {
    display: flex;
    flex-direction: column;
    }

    .appp {
        width: 100%;
        flex: 4 1;
    }

    .appp-2 {
        width: 100%;
        /* height: 100%; */
        padding-left: 3px;
    }

    .img-fluid3 {
        width: 100%;
    }
    
    

    .page-padding {
        position: relative;
        /* width: auto; */
        max-width: 1440px;
        min-height: 1920px;

        /* height: 100vh; For 100% screen height */
        /* width:  100vw; For 100% screen width;  */
        margin: auto;
    }

    .purple-para-web {
       display: none;

        /* width: '5px' !important; */
    }

    .beast-para-web {
        display: none;
    
    }

    .beast-para-mobile {
        display: unset;
    
    }
    
    
    
    .purple-para-mobile {
        display: unset;
    }

}
.post-editor {
  margin: 1em 1%;
}

.post-editor-button {
  float: right;
  margin-top: 1em;
}

.post-body {
  margin: 1em 1%;
}

#main-card-stakkid-button {
}

/* App.css */

/* App.css */

.container-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 4 columns */
  grid-gap: 20px; /* Gap between boxes */
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  padding-left: 165px;
}
#box-one-box {
  width: 350px;
  height: 279px;
  background: #FFF;
  background: var(--White, #FFF);
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);
  /* box-shadow: 0px 3px 6px 0px #6d4d95; */

  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.box-one {
  border: 2px solid #6d4d95;

}

.box-two {
  border: 2px solid #ff0000; /* Red border */
}

.box-three {
  border: 2px solid #ff0000; /* Red border */
}

.box-four {
  border: 2px solid #6d4d95;

}

.box-five {
  border: 2px solid #6d4d95;
}

.box-six {
  border: 2px solid #ff0000; /* Red border */
}

.box-seven {
  border: 2px solid #ff0000; /* Red border */

}

.box-eight {
  border: 2px solid #6d4d95;
}



.logo {
  /* width: 50px; */
  height: 56px;
}

.content {
  text-align: center;
}

p {
  margin: 10px 0;
}

.learn-more {
  background-color: #ffffff;
  color: #646E73;
  color: var(--Greyish, #646E73);
  border: none;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-left: 118px;
/* padding-top: 20px; */

}


@-webkit-keyframes slideInFromLeft {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}


@keyframes slideInFromLeft {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slideInFromRight {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

/* Add animation class for each box */
.box-one {
  -webkit-animation: slideInFromLeft 1s ease-in-out;
          animation: slideInFromLeft 1s ease-in-out;
}

.box-two {
  -webkit-animation: slideInFromRight 1s ease-in-out;
          animation: slideInFromRight 1s ease-in-out;
}

.box-three {
  -webkit-animation: slideInFromLeft 1s ease-in-out;
          animation: slideInFromLeft 1s ease-in-out;
}

.box-four {
  -webkit-animation: slideInFromRight 1s ease-in-out;
          animation: slideInFromRight 1s ease-in-out;
}

.box-five {
  -webkit-animation: slideInFromLeft 1s ease-in-out;
          animation: slideInFromLeft 1s ease-in-out;
}

.box-six {
  -webkit-animation: slideInFromRight 1s ease-in-out;
          animation: slideInFromRight 1s ease-in-out;
}

.box-seven {
  -webkit-animation: slideInFromLeft 1s ease-in-out;
          animation: slideInFromLeft 1s ease-in-out;
}

.box-eight {
  -webkit-animation: slideInFromRight 1s ease-in-out;
          animation: slideInFromRight 1s ease-in-out;
}

.influencer-stack {

    display: flex;
    flex-direction: row;
    /* width: 75rem; */
    justify-content: space-around;
  
}


.Card-1 {
  margin-bottom: 30px;
  width: 300px;
  height: 380px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);
  /* box-shadow: 0px 1px 10px 1px black; */
  overflow: hidden;
}

.Card-2 {
  margin-bottom: 30px;
  width: 300px;
  height: 380px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);
  /* box-shadow: 0px 1px 10px 1px black; */
  overflow: hidden;
}

.Card-3 {
  margin-bottom: 30px;
  width: 300px;
  height: 380px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25);  /* box-shadow: 0px 1px 10px 1px black; */
  overflow: hidden;
}


.upper-container {
  height: 100px;
  background: linear-gradient(to left, #b4b4b4, #ffffff);
}
.image-container img {
  background: white;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  padding: 5px;
  -webkit-transform: translate(85px, 25px);
          transform: translate(85px, 25px);
}
.image {
  /* border: 2px solid #4ca8d8; */
}
.lower-container {
  margin-top: 60px;
  height: 300px;
  background: white;
  text-align: center;
  font-family: 'titillium web', sans-serif;
}
/* .lower-container h3,
h4,
p {
  margin: 0;
  padding: 0;
}
.lower-container h3 {
  color: #2f2d2e;
  font-weight: 600;
  font-size: 1.2rem;
} */
.lower-container h4 {
  color: #6d4d95;
  font-weight: 700;
  font-size: 1.2rem;
}
.lower-container p {
  margin: 1rem;
  font-weight: 300;
  color: #000000;
  font-size: 0.9rem;
  text-align: justify;
}

.software-container {
  max-width: 1440px;
  margin: 50px auto;
  padding-right: .75rem;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: .75rem;
  padding-left: var(--bs-gutter-x, .75rem);
  font-family: 'DM Sans', sans-serif;
  padding: 30px;
}

.survey-panel {
  background: #edf2eb;
  max-width: 420px;
  margin-left: auto;
  display: flex;
  border-radius: 20px;
}

.ref-com {
  background: #6D4D95;
  border-radius: 10px;
  margin: 0px auto;
  color: #fff;
  padding-left: 10px;
  padding-right: 33px;
  min-width: 360px;
}

.rectangle-block {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto 20px auto;
}

.ref-com li {
  margin-bottom: 3px;
}

.ad-placeholder {
  background: #cfc4dc;
  border-radius: 10px;
  height: 480px;
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 110%;
  margin-right: 0px;

  /* identical to box height, or 42px */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: rgba(33, 33, 33, 0.07);
}

.ad-placeholder img {
  border-radius: 10px;
  height: 400px;
  width: 960px;
}

.ad-placeholder-2 {

  padding-top: 10px;
   font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 110%;
  margin-right: 0px;
  margin-top: 1rem;
  /* identical to box height, or 42px */
  display: flex;
  /* align-items: start; */
  /* letter-spacing: -0.02em; */

  /* color: rgba(0, 0, 0, 0.07); */
}

.video-img {
  width: 100%;
}

.company-title h1 {
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
  font-size: 56px;
  color: #00002B;
  text-decoration: none;
}

.company-title a:hover {
  text-decoration: none;
}

.title_color {
  color: #00002B;
}

.company-short-des {
  font-size: 18px;
  line-height: 28px;
  font-family: 'DM Sans';
}

.company-des {
  font-size: 16px;
  line-height: 28px;
}

.text-centerr {
  align-items: center !important;
 
}

.product-img {
  /* border: 1px solid rgba(109, 77, 149, 0.1); */
  border-radius: 20px;
  height: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 20px;
  background: #6D4D95;
  color: #fff;
}

#software-link  {
  background: #F5F3F8;
  border-radius: 20px;
  padding: 10px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #6D4D95;
  display: flex;
  margin-top: 20px;
}

.software-stakkid-button {
  border: none;
  height: 42px;

  padding: 5px 15px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 20px;
  background: #6D4D95;
  color: #fff;
}

.video-card img {
  border-radius: 14px;
}

.ref-com-icon {
  width: 50px;
}

.video-des {
  color: #261B34;
  margin-top: 10px;
}

.company-title-icon {
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.company-detail img {
  width: 30px;
}

.review-detail-com {
  display: flex;
  align-items: center;
}

.company-detail span {
  /* display: block; */
  margin-bottom: 2px;
}

.video-card {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.company-detail li::before {
  display: none;
}

.rate-card-brands {
  background-size: cover;
  background-position: center;
}

.rate-card-brands img {
  max-width: 160px;
  border-radius: 0px 20px 20px 0px;
}

.survey-panel .software-stakkid-button {
  background: #e0934f;
  padding: 5px 15px;

  color: #000;
  border-radius: 20px;
  font-weight: bold;
}

.flash-message {
  color: #18bc9c;
  margin-top: 30px;
  display: block;
}

.error-message {
  color: #e0934f;
}

.ref-com-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.remove-btn {
  background: red;
}

.video-img-bg {
  width: 315px;
  height: 215px;
  border-radius: 14px;
  background: #000;
  display: flex;
  align-items: center;
  justify-self: center;
  margin-bottom: 20px;
}

.rate-card {
  background: #EBE6F0;
  border-radius: 10px;
}

.software-modal .MuiIconButton-root {
  margin-left: auto;
  display: block;
}

#rate-description {
  font-weight: bold;
  margin-top: 20px;
  font-size: 20px;
    font-family: 'DM Sans';
}

.software-stakkid-button-rate-1 {
  background: #6D4D95;
  color: #fff;
  border-radius: 30px;
  font-weight: bold;
  font-size: 18px;  
  margin-left: 10px;
  margin-top: -1px;
  border-width: 0px;
  padding: 5px 30px;
}

.software-stakkid-button-rate {
  background: #6D4D95;
  color: #fff;
  border-radius: 30px;
  font-weight: bold;
  margin-top: 20px;
  border-width: 0px;
  padding: 10px 40px;
  margin-bottom: 40px;
}

.software-username{
  background: grey;
  color: #fff;
  border-radius: 30px;
  font-weight: bold;
  margin-top: 20px;
  border-width: 0px;
  padding: 10px 40px;
  margin-bottom: 40px;
}

.software-modal .MuiBox-root-1{
  min-width: 350px;
}

@media (max-width: 998px) {
  .survey-panel {
    margin: 30px auto;
  }

  .ref-com {
    margin: 10px 20px;
  }

  .video-card:nth-child(0) {
    margin-top: 30px;
  }
}
ul.MuiList-root.MuiList-padding{
  display: flex;
  flex-wrap: wrap;
}
li.MuiListItem-gutters{
  width: 50%;
}
.IonCardWrap{
  margin-top: 0px;
}
#save_btn .button-native {
  background: '#911af8';
  border-radius: 30px;
}
.page-padding{
  padding-top: 0px;
}
.item-lines-none{
  --min-height: 37.5px;
}
.item-interactive{
  --min-height: 38px;
}
@media (max-width: 500px) {
  .ass-card .makeStyles-root-9{
    min-width: 160px;
  }
  #add-more{
    min-width: 160px !important;
  }
}
@media (max-width: 400px) {
  .ass-card .makeStyles-root-9, #add-more {
    min-width: 120px !important;
  }

}
.reset-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh; /* This will make the container take the full viewport height */
  /* background-color: #f0f0f0; Add a background color if needed */
}

.rating-wrapper {
  position: relative;
  display: inline-block;
}

.rating, .rate {
  color: #fcc201;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
}
.rating-wrapper::before,
.rating::before {
  font-family: 'FontAwesome';
  content: '\f005 \0020 \f005 \0020 \f005 \0020 \f005 \0020 \f005';
}

.rate-wrapper::before,
.rate::before {
  font-family: 'FontAwesome';
  content: '\f005 \0020';
}

.search-result-rating {
   border: 2px dashed blue; 
   display: 'inline-flex';
   align-self:   'center'; 
}

.reset-textBox {
  margin-bottom: 13px;
  border-radius: 20px;
  padding: 5px 15px;
  /* font-weight: bold; */
  /* border: none; */
  font-size: 17px;
  font-family: 'Nunito Sans', sans-serif;
}

.reset-btn {
  background-color: #6d4d95;
  color: white;
  border-radius: 20px;
  padding: 5px 15px;
  font-weight: bold;
  border: none;
  font-size: 17px;
  font-family: 'Nunito Sans', sans-serif;
}
.flex-container {
  display: flex;

}

.flex-child {
  flex: 1 1;
  text-align: center;
}

.flex-child:first-child {
  margin-right: 5px;

}

#main-card-stakkid-button-4 {
  background-color: #4ec251;
  border: none;
  color: white;
  border-radius: 2px;
  padding: 3px 15px;
  font-weight: bolder;
  font-size: 20px;
  /* padding: 1px 1px; */
  /* display: flex; */
  /* flex: 1 1 0%; */
}

#main-card-stakkid-button-4:disabled {
  background-color: #6d4d95;
}

.slide-writing {
    width: 100%;
    height: 100%;
    
}

.slide-rating {
    position: absolute;
    /* position: relative; */
  
    width: 645px;
    min-height: 340px;
    border-radius: 6px;
    margin-top: -41%;
    margin-left: 3%;
  
    /* text-align: left;
    justify-content: center; */
  }
  
  .slide-t {
    /* font-weight: bold; */
    font-family: 'DM Sans', sans-serif;
    font-size: 56px;
    color: #000000;
    /* margin-bottom: 1%; */
    line-height: 25px;
  }

  .slide-t-2 {
    /* font-weight: bold; */
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    color: #000000;
    padding-top: 3%;
    /* line-height: 25px;/ */
  }

  .buttons-card-1 {
    display: flex;
    justify-content: space-evenly;
  }

  .buttons-card-3 {
    display: flex;
    justify-content: space-between;
  }

  .rateBtn {
    background-color: #6d4d95;
    border-radius: 20px
  }
.modalBackground {
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.5);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -37%;
    margin-left: -3%;
  }
  
  .modalContainer {
    width: 500px;
    height: 500px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    /* padding: 25px; */
  }
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modalContainer .body {
    flex: 50% 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
  }
  
  .modalContainer .footer {
    flex: 20% 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: crimson;
  }
  
  .modalContainer {
    width: 500px;
    height: 500px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }

  
  .modalContainer .body {
    flex: 50% 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
  }
  
  .modalContainer .footer {
    flex: 20% 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: #6d4d95;
    border-radius: 20px;
  }
.line {
    position: relative;
}
.line:after {
    content: '';
    position: absolute;
    right: 0;
    border-right: 1px solid #cfc7c0;
    top: 10%;
    bottom: 10%;
}
.clientPage {
    /* display: flex; */
    /* width: 1440px; */
    max-width: 1440px;
    min-height: 1920px;
    padding: 36.09px 338px 1841.91px 200px;

    position: relative;
    /* width: auto; */
/* max-width: 1440px;
    min-height: 1920px; */

    /* height: 100vh; For 100% screen height */
    /* width:  100vw; For 100% screen width;  */
    margin: auto;


    /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* flex-direction: column; */


}

.header2 {
    /* position: absolute;
    width: 1000px;
    height: 42px;
    left: 405px;
    top: 37px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 110%;

    display: flex;
    letter-spacing: -0.02em;


    color: #212121; */
    right: -38.5px;

    width: 1000px;
    color: #212121;
    font-family: DM Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 41.8px; /* 87.083% */
    letter-spacing: -0.76px;


}

.para1 {
    /* position: absolute;
    width: 1325px;
    height: 56px;
    left: 481px;
    top: 215.609px;

    font-family: 'DM Sans';
    font-style: normal;
font-weight: 700;
    font-size: 18px;
    line-height: 26.4px; 


    display: flex;
    align-items: center;
    text-align: center;

    color: #6D4D95; */
    width: 1325px;
    /* position: absolute; */
right: -37.5px;
top: 230.609px;
padding-bottom: 5px;
color: #666677;
font-family: DM Sans;
font-size: 24px;
font-style: normal;
font-weight: 200;
line-height: 26.4px; /* 110% */
letter-spacing: -0.48px;

}

.para-img {
    width: 1047px;
}

.para2 {
    /* position: absolute;
    width: 1250px;
    height: 469px;
    left: 124px;
    top: 440px;

    background: #FBFBFB;
    border-radius: 16px; */

    width: 1047px;
height: 525px;
/* position: absolute; */
left: 153.5px;
margin-top: -20px;
bottom: -90px;
/* border-radius: 16px; */
background: #F4F4F4;
}


.card-para-one {
    display: flex;
    flex-shrink: 0;


width: 407px;
padding: 30px 0px 0px 50px;
align-items: left;
color: #667;
font-family: DM Sans;
font-size: 30px;
font-style: normal;
font-weight: 400;
line-height: 37.5px;
}


.para-image {
    /* left: 153.5px; */

    margin-top: 30px;
    width: 1087px;
    height: 525px;
}

.para-laptop {
    position: absolute;
left: 9.45%;
right: 9.55%;
top: 0%;
bottom: 5.04%;

background: #8B67B8;
}

.para-left {
    position: absolute;
    max-width: 532px;
    max-height: 612px;
    left: 0px;
    top: 24px;
    
    background: #FBFBFB;
    border-radius: 16px;
}

.para-right {
    position: absolute;
    width: 1030px;
    height: 612px;
    left: 734px;
    top: 74px;

    /* background: #FCFCFC; */
    border-radius: 16px;
}

.para-title {
    color: #212121;
    text-align: center;
    font-family: DM Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 106.4px; /* 55% */
    letter-spacing: -0.48px;
}

.para-group {
    width: 486px;
    height: 340px;
    flex-shrink: 0;
    margin-left: 55%;
    margin-top: -11.5%;
    
    letter-spacing: -0.48px;


}

ul {
    list-style: none; /* Remove default bullets */
  }
  
 

.para-one {
    display: flex;
width: 469px;
padding: 10px 39px 0px 0px;
align-items: flex-start;
grid-gap: 11px;
gap: 11px;
    color: #757575;
font-family: DM Sans;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 25px; /* 155.556% */
}

.para-two {
    display: flex;
width: 490px;
padding: 30px 151px 2px 0px;
align-items: flex-start;
grid-gap: 11px;
gap: 11px;
    color: #757575;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 155.556% */
}

.para-three {
    display: flex;
width: 460px;
padding: 30px 10px 0px 0px;
align-items: flex-start;
grid-gap: 11px;
gap: 11px;
    color: #757575;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 155.556% */
}

.para-four {
    display: flex;
width: 461px;
height: 108px;
padding: 40px 23px 30px 0px;
align-items: flex-start;
grid-gap: 11px;
gap: 11px;
flex-shrink: 0;
    color: #757575;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 155.556% */
}



.card-one {
    position: absolute;
width: 400px;
height: 335px;
left: 125px;
top: 90px;



background: #FCFCFC;
border-radius: 16px;
}

.card-one-title {
    /* width: 238px; */
    height: 26px;

padding-top: 60px;

    padding-left: 90px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    /* or 26px */
    
    display: flex;
    align-items: center;
    text-align: center;

    letter-spacing: -0.02em;
    
    /* systemGrey/900 */
    
    color: #212121;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}

.card-one-para {
    width: 369px;
    height: 112px;

padding-top: 60px;
    padding-left: 50px;

    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    text-align: center;
    
    color: #757575;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    flex-grow: 0;
}

.card-one-button {
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 26px;
grid-gap: 12px;
gap: 12px;

position: absolute;
width: 150px;
height: 56px;
left: 135px;
top: 230px;
color: #FFFFFF;
font-weight: bolder;
background: #AEA7FF;
border-radius: 5px;

}

.card-two {

position: absolute;
width: 400px;
height: 335px;
left: 549px;
top: 90px;

background: #FCFCFC;
border-radius: 16px;
}

.card-two-title {
    /* width: 231px; */
    height: 26px;

    padding-top: 60px;
    padding-left: 90px;

    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    /* or 26px */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    /* systemGrey/900 */
    
    color: #212121;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}

.card-two-para {
    width: 369px;
    height: 112px;
    padding-top: 60px;
    padding-left: 50px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    text-align: center;
    
    color: #757575;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    flex-grow: 0;
}

.card-two-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 26px;
    grid-gap: 12px;
    gap: 12px;
    
    position: absolute;
    width: 150px;
    height: 56px;
    left: 135px;
    top: 230px;
color: #FFFFFF;
font-weight: bolder;

background: #AEA7FF;
    border-radius: 5px;
}

.card-three {
    position: absolute;
    width: 400px;
    height: 335px;
    left: 973px;
    top: 90px;
    
    background: #FCFCFC;
    border-radius: 16px;
}

.card-three-title {
    /* width: 124px; */
    height: 26px;

    padding-top: 60px;

    padding-left: 140px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    /* or 26px */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    /* systemGrey/900 */
    
    color: #212121;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}

.card-three-para {
    width: 369px;
    height: 140px;

    padding-top: 30px;
    padding-left: 50px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    text-align: center;
    
    color: #757575;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    flex-grow: 0;
}

.card-three-button {
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 26px;
grid-gap: 12px;
gap: 12px;

position: absolute;
width: 150px;
height: 56px;
left: 135px;
    top: 230px;
color: #FFFFFF;
font-family: 'DM Sans';
font-style: normal;
font-weight: 900;
background: #AEA7FF;

border-radius: 5px;
}

/* .rectangle-one {
    position: absolute;
width: 1254px;
height: 284px;
left: 120px;
top: 930px;
padding-bottom: 30px;
border-radius: 5px;
background: #7F59AE; */

/* background: linear-gradient(180deg, #875EB9 -9.54%, rgba(109, 77, 149, 0) 93.32%); */
/* } */

/* .rectangle-title {
    position: absolute;
    width: 189px;
    height: 42px;
    left: 105px;
    top: 77px;
    bottom: 0px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 110%; */
    /* identical to box height, or 42px */
    
    /* display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #FFFFFF;
    
} */

/* .rectangle-para {
    position: absolute;
    width: 789px;
    height: 56px;
    left: 105px;
    top: 151px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px; */
    /* or 156% */
    
    /* display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
} */

.rectangle-button-one {
    text-transform: uppercase;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 26px;
    grid-gap: 12px;
    gap: 12px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 900;
    /* position: absolute; */
    width: 250px;
    height: 56px;
    margin-left: 47px;
    margin-top: -200px;
    color:#ffffff;
    background: #7F59AE;
    border-radius: 30px;
}

@media only screen and (max-device-width: 844px) {


    .rectangle-button-one {
     
          width: 250px;
        height: 56px;
        margin-left: 58px;
        margin-top: 51px;
        color:#ffffff;
        background: #7F59AE;
        border-radius: 30px;
    }

    /* .clientPage-1 {
      
    display: none;
    
    } */

    .card-para-one {
        display: flex;
    flex-shrink: 0;
    width: 356px;
    padding: 47px 0px 0px 13px;
    align-items: left;
    color: #667;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 37.5px;
    }

    .clientPage {
        position: relative;
        /* width: auto; */
        /* max-width: none; */
        margin: auto;
    
        /* height: 100vh; For 100% screen height */
        width:  100vw; 
       
    
    
    }

    .para1 {
        left: 13px !important;
        top: 34px !important;
        width: 377px;
        position: absolute;
        right: -37.5px;
        top: 230.609px;
        padding-bottom: 5px;
        color: #666677;
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 26.4px;
        letter-spacing: -0.48px;
    }

    .header2 {
        position: relative;
        width: 692px;
        height: 42px;
        left: -186px;
        top: -31px;
    
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 110%;
        /* identical to box height, or 42px */
    
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
    
        /* systemGrey/900 */
    
        color: #212121;
    
    }
    
    .card-one {
        position: absolute;
    width: 400px;
    height: 335px;
    left: -10px;
    top: 90px;
    
    
    
    background: #FCFCFC;
    border-radius: 16px;
    }

    .card-two {

        position: absolute;
        width: 400px;
        height: 335px;
        left: -10px;
        top: 450px;
        
        background: #FCFCFC;
        border-radius: 16px;
        }

        .card-three {
            position: absolute;
            width: 400px;
            height: 335px;
            left: -10px;
            top: 820px;
            
            background: #FCFCFC;
            border-radius: 16px;
        }


.para2 {
    position: absolute;
    width: 360px;
    height: 469px;
    left: 10px;
    top: 231px;

    background: #FBFBFB;
    border-radius: 16px;
}

.rectangle-one {
    position: absolute;
    width: 360px;
height: 184px;
left: 18px;
top: 1730px;
padding-bottom: 30px;
border-radius: 5px;
background: #7F59AE;

/* background: linear-gradient(180deg, #875EB9 -9.54%, rgba(109, 77, 149, 0) 93.32%); */
}

.para-image {
    position: absolute;
width: 352px !important;
max-height: 176px;
left: 10px;
top: 36px;

background: #bcbcbc;
border-radius: 16px;
}


.para-img {
    position: absolute;
width: 352px !important;
/* max-height: 612px; */
left: 0px;
top: 0px;

background: #FBFBFB;
border-radius: 16px;
}

.rectangle-title {
    position: absolute;
    width: 189px;
    height: 42px;
    left: 20px;
    top: 27px;
    bottom: 0px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 110%;
    /* identical to box height, or 42px */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #FFFFFF;
    
}

.rectangle-para {
    position: absolute;
    width: 350px;
    height: 56px;
    left: 20px;
    top: 91px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
}

.rectangle-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 26px;
    grid-gap: 12px;
    gap: 12px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 900;
    position: absolute;
    width: 140px;
    height: 56px;
    left: 205px;
    top: 18px;
    font-size: 10px;

    color:#7F59AE;
    background: #FFFFFF;
    border-radius: 5px;
}


.para-right {
    position: absolute;
    width: 200px;
    height: 612px;
    left: 15px;
    top: 294px;

    /* background: #FCFCFC; */
    border-radius: 16px;
}


.para-title {
    position: absolute;
    width: 337px;
    height: 26px;
    left: 11px;
    top: 14px;
    padding-bottom: -14px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 110%;
    /* or 26px */

    /* display: flex;
    align-items: center; */
    letter-spacing: -0.02em;

    color: #6D4D95;
}

.para-group {
    padding-top: 100px;
    margin-left: 5%;
    height: 312px;

   }



.para-one {
    width: 337px;
    height: 56px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 28px;
    /* or 156% */
    padding-top: 10px;

padding-bottom: 0px;
    /* display: flex;
    align-items: center; */

    color: #757575;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.para-two {
    width: 337px;
    height: 28px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 28px;
    /* identical to box height, or 156% */

    padding-top: 10px;

    padding-bottom: 30px;

    /* display: flex;
    align-items: center; */

    color: #757575;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.para-three {
    width: 337px;
    height: 56px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 28px;
    /* or 156% */

    padding-top: 15px;

    padding-bottom: 0px;

    /* display: flex;
    align-items: center; */

    color: #757575;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.para-four {
    width: 337px;
    /* height: 56px; */

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 28px;
    /* or 156% */

    padding-top: 0px;

    padding-bottom: 30px;

    /* display: flex;
    align-items: center; */

    color: #757575;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}


}
.clientPage1{
    /* width: 1440px; */
    max-width: 1440px;
    /* width: 1486px; */
    min-height: 1920px;
    margin: auto;
    position: relative;

}

.header-one {
    width: 1486px;
    height: 600px;
    flex-shrink: 0; 

}

.darkscreen {
    background: rgba(0, 0, 0, 0.40);
    position: absolute;
    height: 650px;
  width: 100%;
  /* top: 144px; */
  left: 0;
  /* background: rgba(0, 0, 0, 0.65); */
  /* color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;


}

.header-img {
    width: 1440px;
    /* height: 511px; */
    flex-shrink: 0; 
    background: rgba(0, 0, 0, 0.40);
    background-size: cover;

    /* margin-top: -8%; */
}

.header-text {
    position: relative;

    color: #ffffff;

    color: var(--white, #ffffff);
    font-family: Lato;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    width: 597px;
    margin-top: -444px;
    padding-left: 108px;
}

.body-one {
    width: 1450px;
height: 615px;
flex-shrink: 0;
overflow: hidden; /* add this to contain floated children */

}


.body-para {
    width: 706px;
    margin-top: 130px;
    margin-left: 3%;
    float:left; /* add this */


}

.para-body-img {
    /* float: left; */
    overflow: hidden; /* add this to contain floated children */
    margin-top: 63px;
    padding-left: 35px;


}


.body-img {
    width: 608px;
    height: 502px;
    flex-shrink: 0;

}

.para-one-body {
    display: flex;
width: 469px;
padding: 10px 39px 0px 0px;
align-items: flex-start;
grid-gap: 11px;
gap: 11px;
    color: #757575;
font-family: DM Sans;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 35px; /* 155.556% */
}

.para-two-body {
    display: flex;
width: 700px;
padding: 30px 151px 2px 0px;
align-items: flex-start;
grid-gap: 11px;
gap: 11px;
    color: #757575;
    font-family: DM Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 155.556% */
}

.para-three-body {
    display: flex;
width: 534px;
padding: 34px 10px 0px 0px;
align-items: flex-start;
grid-gap: 11px;
gap: 11px;
    color: #757575;
    font-family: DM Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 155.556% */
}

.para-four-body {
    display: flex;
width: 461px;
height: 108px;
padding: 40px 23px 30px 0px;
align-items: flex-start;
grid-gap: 11px;
gap: 11px;
flex-shrink: 0;
    color: #757575;
    font-family: DM Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 155.556% */
    /* margin-top: -26.5%; */

}

.para-title-body {
    width: 486px;
    /* color: var(--dark, #2D3748); */
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: -32px;

}

.para-group-body {
    width: 486px;
    height: 340px;
    flex-shrink: 0;
    padding-top: 5%;
    /* margin-left: 55%; */
}


.body-two {
    width: 1450px;
    margin-left: 10%;

    overflow: hidden; /* will contain if #first is longer than #second */

}

.body-para-one {
    width: 588px;
    float:left;
}

.body-h2-one {
    /* color: var(--dark, #2D3748); */
font-family: Lato;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
    
}

.body-p-one {
    color: #646E73;
    color: var(--greyish, #646E73);
font-family: Lato;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.body-para-two {
    overflow: hidden; /* if you don't want #second to wrap below #first */
    width: 588px;
    padding-left: 30px;



}

.body-h2-two {
    /* color: var(--dark, #2D3748); */
font-family: Lato;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.body-p-two {
    color: #646E73;
    color: var(--greyish, #646E73);
font-family: Lato;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}


.rectangle-one {
    position: absolute;
width: 1254px;
height: 284px;
left: 120px;
top: 1609px;
/* padding-top: 30%; */
border-radius: 5px;
background: #7F59AE;

/* background: linear-gradient(180deg, #875EB9 -9.54%, rgba(109, 77, 149, 0) 93.32%); */
}

.rectangle-title {
    position: absolute;
    width: 189px;
    height: 42px;
    left: 105px;
    top: 77px;
    bottom: 0px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 110%;
    /* identical to box height, or 42px */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #FFFFFF;
    
}

.rectangle-para {
    position: absolute;
    width: 789px;
    height: 56px;
    left: 105px;
    top: 151px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
}

.rectangle-button {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 26px;
    grid-gap: 12px;
    gap: 12px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 900;
    position: absolute;
    width: 250px;
    height: 56px;
    left: 980px;
    top: 151px;
    color:#7F59AE;
    background: #FFFFFF;
    border-radius: 30px;
}

.rectangle-button-o {
padding-top: 40px;
}



.rectangle-button-two {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 26px;
    grid-gap: 12px;
    gap: 12px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 900;
    position: absolute;
    width: 250px;
    height: 56px;
    left: 621px;
    color:#ffffff;
    background: #7F59AE;
    border-radius: 30px;
}
