


.abtPage1{
    /* width: 1440px; */
    max-width: 1440px;
    /* max-width: 1440px; */

    /* width: 1486px; */
    min-height: 2392px;
    margin: auto;
    position: relative;

}

.abtheader-img {
    width: 1440px;
    /* height: 511px; */
    flex-shrink: 0; 
    background: rgba(0, 0, 0, 0.40);
    background-size: cover;

    /* margin-top: -8%; */
}

.circle-about1 {
    width: 153.091px;
    height: 475.21px;
    transform: rotate(0.359deg);
    flex-shrink: 0;
    background: url('../assets/images/Dots.png'), lightgray 50% / cover no-repeat;
    background-blend-mode: overlay;
    mix-blend-mode: overlay;
}

.circle-about2 {
    width: 150px;
    height: 147px;
    flex-shrink: 0;
    fill: #4B2B8E;
}

.clientPage1{
    /* width: 1440px; */
    max-width: 1440px;
    /* width: 1486px; */
    min-height: 1920px;
    margin: auto;
    position: relative;

}

.header-one {
    width: 1440px;
    height: 600px;
    flex-shrink: 0; 

}

.darkscreen {
    background: rgba(0, 0, 0, 0.40);
    position: absolute;
    height: 650px;
  width: 100%;
  /* top: 144px; */
  left: 0;
  /* background: rgba(0, 0, 0, 0.65); */
  /* color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;


}

.header-img {
    width: 1440px;
    /* height: 511px; */
    flex-shrink: 0; 
    background: rgba(0, 0, 0, 0.40);
    background-size: cover;

    /* margin-top: -8%; */
}

.header-text {
    position: relative;

    color: var(--white, #ffffff);
    font-family: Lato;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    width: 597px;
    margin-top: -444px;
    padding-left: 108px;
}

.body-one1 {
    width: 1450px;
height: 470px;
flex-shrink: 0;
overflow: hidden; /* add this to contain floated children */

}


.body-para {
    width: 706px;
    margin-top: 130px;
    margin-left: 3%;
    float:left; /* add this */


}

.para-body-img {
    /* float: left; */
    overflow: hidden; /* add this to contain floated children */
    margin-top: 63px;
    padding-left: 35px;


}


.body-img2 {
    width: 597px;
    height: 391px;
    flex-shrink: 0;

}

.para-one-body1 {
    display: flex;
width: 669px;
padding: 10px 39px 0px 0px;
align-items: flex-start;
gap: 11px;
color: var(--greyish, #646E73);
    font-family: Lato;
    font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.para-two-body {
    display: flex;
width: 700px;
padding: 30px 151px 2px 0px;
align-items: flex-start;
gap: 11px;
    color: #757575;
    font-family: DM Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 155.556% */
}

.para-three-body {
    display: flex;
width: 534px;
padding: 34px 10px 0px 0px;
align-items: flex-start;
gap: 11px;
    color: #757575;
    font-family: DM Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 155.556% */
}

.para-four-body {
    display: flex;
width: 461px;
height: 108px;
padding: 40px 23px 30px 0px;
align-items: flex-start;
gap: 11px;
flex-shrink: 0;
    color: #757575;
    font-family: DM Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 155.556% */
    /* margin-top: -26.5%; */

}

.para-title-body1 {
    width: 486px;
    /* color: var(--dark, #2D3748); */
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: -32px;

}

.para-group-body1 {
    width: 686px;
    height: 340px;
    flex-shrink: 0;
    /* padding-top: 5%; */
    /* margin-left: 55%; */
}


.body-two1 {
    width: 1450px;
    margin-left: 3%;

    overflow: hidden; /* will contain if #first is longer than #second */

}

.body-para-one1 {
    width: 669px;
    /* margin-top: 130px; */
    /* margin-left: -3%; */
    float:left; /* add this */
}

.body-h2-one {
    /* color: var(--dark, #2D3748); */
font-family: Lato;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
    
}

.body-p-one1 {
    color: var(--greyish, #646E73);
font-family: Lato;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.body-para-two1 {
    overflow: hidden; /* if you don't want #second to wrap below #first */
    width: 669px;
    padding-left: 30px;
    padding-top: 44px;



}

.body-h2-two {
    /* color: var(--dark, #2D3748); */
font-family: Lato;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.body-p-two {
    color: var(--greyish, #646E73);
font-family: Lato;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}


.rectangle-one1 {
    position: absolute;
    width: 653px;
    height: 503px;
    flex-shrink: 0;
left: 55px;
top: 1502px;
/* padding-top: 30%; */
border-radius: 5px;
background: #7F59AE;

/* background: linear-gradient(180deg, #875EB9 -9.54%, rgba(109, 77, 149, 0) 93.32%); */
}



.rectangle-title1 {
    position: absolute;
    width: 289px;
    height: 42px;
    left: 55px;
    top: 77px;
    bottom: 0px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 110%;
    /* identical to box height, or 42px */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #FFFFFF;
    
}

.rectangle-para1 {
    position: absolute;
    width: 540px;
    height: 56px;
    left: 55px;
    top: 151px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
}

.rectangle-title2 {
    position: absolute;
    width: 289px;
    height: 42px;
    left: 55px;
    top: 294px;
    bottom: 0px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 110%;
    /* identical to box height, or 42px */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #FFFFFF;
    
}

.rectangle-para2 {
    position: absolute;
    width: 789px;
    height: 56px;
    left: 55px;
    top: 340px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
}


.rectangle-para3 {
    position: absolute;
    width: 789px;
    height: 56px;
    left: 55px;
    top: 381px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
}

.rectangle-two1 {
    position: absolute;
    width: 653px;
    height: 503px;
    flex-shrink: 0;
left: 729px;
top: 1502px;
/* padding-top: 30%; */
border-radius: 5px;
background: #7F59AE;

/* background: linear-gradient(180deg, #875EB9 -9.54%, rgba(109, 77, 149, 0) 93.32%); */
}


.rectangle-title4 {
    position: absolute;
    width: 289px;
    height: 42px;
    left: 55px;
    top: 77px;
    bottom: 0px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 110%;
    /* identical to box height, or 42px */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #FFFFFF;
    
}

.rectangle-title5 {
    position: absolute;
    width: 289px;
    height: 42px;
    left: 211px;
    top: 161px;
    bottom: 0px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32.612px; /* 163.058% */    /* identical to box height, or 42px */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #FFFFFF;
    
}

.rectangle-para4 {
    position: absolute;
    width: 789px;
    height: 56px;
    left: 211px;
    top: 186px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15.613px;

    line-height: 31.226px; /* 200% */
    /* or 156% */
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
}


.rectangle-para5 {
    position: absolute;
    width: 789px;
    height: 56px;
    left: 211px;
    top: 217px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15.613px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
}

.rectangle-para6 {
    position: absolute;
    width: 497px;
    height: 201px;
    flex-direction: column;
    justify-content: center;
    left: 55px;
    top: 280px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
}

.body-img1 {
    position: absolute;

    width: 132px;
    height: 135px;
    left: 57px;
    top: 146px;
    flex-shrink: 0;
    border-radius: 92px;
border: 6px solid rgba(255, 255, 255, 0.23);
/* background: url(<path-to-image>), lightgray 0px -9.916px / 100% 121.557% no-repeat, #FFC130; */
}

.rectangle-button {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 26px;
    gap: 12px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 900;
    position: absolute;
    width: 250px;
    height: 56px;
    left: 980px;
    top: 151px;
    color:#7F59AE;
    background: #FFFFFF;
    border-radius: 30px;
}

.rectangle-button-o1 {
padding-top: 60px;
}



.rectangle-button-two1 {
    /* text-transform: uppercase; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 26px;
    gap: 12px;
    
    position: absolute;
    width: 669px;
    height: 56px;
    left: 381px;
    

    color: var(--greyish, #646E73);
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}