:root {
  --ion-color-primary: #008bc0;
  --ion-color-primary-rgb: 0, 139, 192;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #007aa9;
  --ion-color-primary-tint: #1a97c6;
}

.Chart-01 {
  align-self: flex-start;
  display: flex;
}

.Chart-02 {
  display: flex;
  justify-content: center;
  align-self: flex-start;
  margin: 20px;
}

.Chart-03 {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin: 20px;
}

.Dashboard-rear {
  display: flex;
}

.add-tool {
  text-align: right;
  margin: 20px;
  max-width: 80%;
}

/* .Chart {
  padding: 0.5em;
} */

@media only screen and (min-device-width: 390px) and (max-device-width: 844px) {
  /* Styles */

  .Dashboard-rear {
    display: grid;
  }

  .Chart-01 {
    align-self: flex-start;
    flex-direction: row;

    display: flex;
  }

  .Chart-02 {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-self: flex-start;
    margin: 20px;
  }

  .Chart-03 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px;
  }
}