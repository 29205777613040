.clientPage1{
    /* width: 1440px; */
    max-width: 1440px;
    /* width: 1486px; */
    min-height: 1920px;
    margin: auto;
    position: relative;

}

.header-one {
    width: 1486px;
    height: 600px;
    flex-shrink: 0; 

}

.darkscreen {
    background: rgba(0, 0, 0, 0.40);
    position: absolute;
    height: 650px;
  width: 100%;
  /* top: 144px; */
  left: 0;
  /* background: rgba(0, 0, 0, 0.65); */
  /* color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;


}

.header-img {
    width: 1440px;
    /* height: 511px; */
    flex-shrink: 0; 
    background: rgba(0, 0, 0, 0.40);
    background-size: cover;

    /* margin-top: -8%; */
}

.header-text {
    position: relative;

    color: var(--white, #ffffff);
    font-family: Lato;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    width: 597px;
    margin-top: -444px;
    padding-left: 108px;
}

.body-one {
    width: 1450px;
height: 615px;
flex-shrink: 0;
overflow: hidden; /* add this to contain floated children */

}


.body-para {
    width: 706px;
    margin-top: 130px;
    margin-left: 3%;
    float:left; /* add this */


}

.para-body-img {
    /* float: left; */
    overflow: hidden; /* add this to contain floated children */
    margin-top: 63px;
    padding-left: 35px;


}


.body-img {
    width: 608px;
    height: 502px;
    flex-shrink: 0;

}

.para-one-body {
    display: flex;
width: 469px;
padding: 10px 39px 0px 0px;
align-items: flex-start;
gap: 11px;
    color: #757575;
font-family: DM Sans;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 35px; /* 155.556% */
}

.para-two-body {
    display: flex;
width: 700px;
padding: 30px 151px 2px 0px;
align-items: flex-start;
gap: 11px;
    color: #757575;
    font-family: DM Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 155.556% */
}

.para-three-body {
    display: flex;
width: 534px;
padding: 34px 10px 0px 0px;
align-items: flex-start;
gap: 11px;
    color: #757575;
    font-family: DM Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 155.556% */
}

.para-four-body {
    display: flex;
width: 461px;
height: 108px;
padding: 40px 23px 30px 0px;
align-items: flex-start;
gap: 11px;
flex-shrink: 0;
    color: #757575;
    font-family: DM Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 155.556% */
    /* margin-top: -26.5%; */

}

.para-title-body {
    width: 486px;
    /* color: var(--dark, #2D3748); */
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: -32px;

}

.para-group-body {
    width: 486px;
    height: 340px;
    flex-shrink: 0;
    padding-top: 5%;
    /* margin-left: 55%; */
}


.body-two {
    width: 1450px;
    margin-left: 10%;

    overflow: hidden; /* will contain if #first is longer than #second */

}

.body-para-one {
    width: 588px;
    float:left;
}

.body-h2-one {
    /* color: var(--dark, #2D3748); */
font-family: Lato;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
    
}

.body-p-one {
    color: var(--greyish, #646E73);
font-family: Lato;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.body-para-two {
    overflow: hidden; /* if you don't want #second to wrap below #first */
    width: 588px;
    padding-left: 30px;



}

.body-h2-two {
    /* color: var(--dark, #2D3748); */
font-family: Lato;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.body-p-two {
    color: var(--greyish, #646E73);
font-family: Lato;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}


.rectangle-one {
    position: absolute;
width: 1254px;
height: 284px;
left: 120px;
top: 1609px;
/* padding-top: 30%; */
border-radius: 5px;
background: #7F59AE;

/* background: linear-gradient(180deg, #875EB9 -9.54%, rgba(109, 77, 149, 0) 93.32%); */
}

.rectangle-title {
    position: absolute;
    width: 189px;
    height: 42px;
    left: 105px;
    top: 77px;
    bottom: 0px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 110%;
    /* identical to box height, or 42px */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #FFFFFF;
    
}

.rectangle-para {
    position: absolute;
    width: 789px;
    height: 56px;
    left: 105px;
    top: 151px;
    
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    
}

.rectangle-button {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 26px;
    gap: 12px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 900;
    position: absolute;
    width: 250px;
    height: 56px;
    left: 980px;
    top: 151px;
    color:#7F59AE;
    background: #FFFFFF;
    border-radius: 30px;
}

.rectangle-button-o {
padding-top: 40px;
}



.rectangle-button-two {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 26px;
    gap: 12px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 900;
    position: absolute;
    width: 250px;
    height: 56px;
    left: 621px;
    color:#ffffff;
    background: #7F59AE;
    border-radius: 30px;
}