i.fa.fa-youtube-play {
  margin-top: 1px;
}

.hero-btns {
  position: relative;

  top: 190px;
  right: -22%;

  /* text-align: center; */
}

.hero-bt {
  /* position: relative; */
}

.btns {
  position: absolute;

  font-family: 'Nunito Sans', sans-serif;
  /* left: -35px; */
}




.hero-btns-2 {
  position: relative;
  top: 30px;
  left: 0%;
  /* text-align: center; */
}

.btns-2 {
  position: absolute;
  /* padding: 8px 20px; */
  font-family: 'Nunito Sans', sans-serif;
  /* left: -35px; */
}

.hero-btns-3 {
  position: relative;

  top: 30px;
  left: 0%;
  /* text-align: center; */
}

.btns-3 {
  position: absolute;

  font-family: 'Nunito Sans', sans-serif;
  /* left: -35px; */
}

.slides {
  /* border-top: 6px solid #b61e4d; */
}

.hero-btns .btns {
  margin: 6px;
}
.hero-btns-2 .btns-2 {
  margin: 6px;
}
.hero-btns-3.btns-3 {
  margin: 6px;
}

.btns .fa-play-circle {
  margin-left: 4px;
}

.fa fa-youtube-play {
  top: 10px;
}

.slide-studying {
  /* opacity: 0.9; */
}

.slide-writing {
  /* opacity: 0.9; */
}

.slide-friends {
  /* opacity: 0.9; */
}

.slide1 {
  position: relative;
  text-align: center;

  object-fit: cover;
  height: auto;
  line-height: normal;
}


.slide1-text-right {
  position: absolute;
  top: 15%;
  left: 1%;
  color: #6d4d95;
  padding-left: 5px;
  padding-bottom: 72px !important;

  font-family: 'Nunito Sans', sans-serif;
  /* font-weight: bold; */
  font-size: 50px;
  /* line-height: normal; */
  line-height: 62px;
}

.rating-button {
background-color: #6d4d95;
color: white;
}

.slide-title-contain {
  /* display: flex; */
  align-items: center;
}


.slide1-text {
  position: absolute;
  top: 26%;
  /* right: -8%; */
  color: #6d4d95;
  /* padding-right: 15px; */
  /* padding-bottom: 72px !important; */

  font-family: 'Nunito Sans', sans-serif;
  /* font-weight: bold; */
  font-size: 50px;
  /* line-height: normal; */
  line-height: 42px;
}

.slide1-t-1 {
  /* font-weight: bold; */
  font-size: 50px;
  color: #6d4d95;
  /* margin: 0; */
  /* margin-bottom: 1%; */
  /* line-height: 2px; */
}

.slide1-t {
  /* font-weight: bold; */
  font-size: 50px;
  color: #6d4d95;
  /* margin-bottom: 1%; */
  /* line-height: 2px; */
}

.slide-title-1 {
  max-width: 60%;
  position: relative;
  padding-top: -15px;
  /* padding-right: 5px;
  padding-left: 5px; */
  padding-bottom: 47px;

  /* max-width: 100%; */
  height: auto;
  /* margin-right: 10px; */
  /* color: white; */

  /* margin-top: 50%; */
}

.slide1-text-ee {
  /* margin-bottom: 20px; */
  /* line-height: 2px; */
  /* font-weight: bold; */
  font-size: 50px;

  /* padding-bottom: 0; */
  color: #6d4d95;
}




.slide1-text-e {
  margin-bottom: 8px;
  /* line-height: 2px; */
  /* font-weight: bold; */
  font-size: 60px;

  /* padding-bottom: 0; */
  color: #6d4d95;
}

.slide1-tt {
  /* font-weight: bold; */
  font-size: 53px;
  color: #6d4d95;
  /* bottom: -15%; */
  line-height: 2px;
}

.slide1-text-p {
  margin-top: -18px;
  padding-top: 15px;
  font-size: 28px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 100;
  top: 10px;
  line-height: 1px;
}

.slide1-text-pp {
  display: none;
}



.slide2 {
  position: relative;
  text-align: center;
  height: 800px;

  object-fit: cover;
  /* height: 595px; */
  line-height: normal;
}

.slide2-t {
  font-weight: bold;
  font-size: 95px;
  color: #6d4d95;
  /* bottom: -15%; */
  line-height: 2px;
}

.slide2-text {
  position: absolute;
  top: 20%;
  left: -52%;
  color: #6d4d95;
  /* color: #cb0e60; */
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 72px !important;

  font-family: 'Nunito Sans', sans-serif;
  /* font-weight: bold; */
  font-size: 70px;
  /* line-height: normal; */
  line-height: 42px;
}



.slide2-text-e {
  /* margin-top: 12px; */
  /* line-height: 2px; */
  font-size: 55px;

  /* padding-bottom: 0; */
  color: #6d4d95;
}

.slide3-text-e {
  /* margin-top: 12px; */
  /* line-height: 2px; */
  font-size: 55px;

  /* padding-bottom: 0; */
  color: #6d4d95;
}

.slide2-text-p {
  margin-top: -12px;

  font-size: 25px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 100;
  /* top: 10px; */
  line-height: 1px;
}

.slide2-word {
  /* font-style: italic; */
  /* color: white; */
}

.slide3 {
  position: relative;
  text-align: center;
  height: 800px;

  /* object-fit: cover; */
  /* height: 595px; */
}

.slide3-text {
  position: absolute;
  top: 20%;
  left: -50%;
  color: #6d4d95;
  padding-left: 5px;
  padding-bottom: 72px !important;

  font-family: 'Nunito Sans', sans-serif;
  /* font-weight: bold; */
  font-size: 70px;
  /* line-height: normal; */
  line-height: 42px;
}

.slide-title {
  max-width: 15%;
  position: relative;
  top: 2px;
}

.slide-title-2 {
  max-width: 20%;
  position: relative;
  padding-top: 25px;
  padding-right: 5px;
  padding-left: 5px;

  color: white;

  /* margin-top: 50%; */
}

.slide-title-3 {
  max-width: 20%;
  position: relative;
  padding-top: 15px;
  padding-right: 5px;
  padding-left: 5px;

  color: white;

  /* margin-top: 50%; */
}

.slide3-text-p1 {
  font-weight: bold;
  font-size: 95px;
  color: #6d4d95;
  /* bottom: -15%; */
  line-height: 2px;
}

.slide3-text-p {
  margin-top: 35px;

  font-size: 25px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 100;
  /* top: 10px; */
  line-height: 1px;
}

/* .slide-title-3 {
  max-width: 15%;
  margin-right: 19px;
  bottom: 18%;
} */

@media screen and (max-width: 1141px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }

  .slide1-text-e {
    font-size: 48px;
    /* margin-top: -57px; */
    /* margin-left: 29%; */
  }

  .slide1-text {
    /* font-size: 48px; */
    /* margin-top: -57px; */
    margin-left: 59%;
  }

  .slide1-text-p {
    font-size: 25px;
    /* margin-top: -57px; */
    /* margin-left: 29%; */
  }

  .hero-btns {
    /* max-width: 65%; */
    /* display: none; */
    top: 130px;
    /* left: 62%; */
  }
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media only screen and (max-device-width: 844px) {
  .slide1-t-1 {
    /* font-weight: bold; */
    font-size: 30px;
    color: #6d4d95;
    width: 157px;
    /* margin: 0; */
    margin-bottom: 1%;
    line-height: 12px;
  }


  .slide1 {
    height: auto;
  }

  .slide1-t {
    font-size: 14px;
    /* margin-left: 10%; */
  }

  .slide1-text-ee {
    font-size: 23px;
    margin-top: -45px;
    /* margin-left: 30%; */
  }

  .slide1-text-e {
    font-size: 18px;
    margin-top: -77px;
    /* margin-left: 29%; */
  }
  .slide1-text-p {
    display: none;
  }
  .slide1-text-pp {
    display: block;
    font-size: 10px;
    margin-top: -70px;
    /* margin-bottom: -65px; */

    /* margin-left: 27%; */
    /* word-break: Keep all; */
  }
  .text-pp {
    margin-top: -10px;
    /* margin-bottom: -65px; */

    /* margin-left: 27%; */
    /* word-break: Keep all; */
  }

  .slide-title-1 {
    max-width: 15%;
    /* position: relative; */
    /* display: block; */
    padding-bottom: 20px;
    /* margin-top: -50px; */

    max-width: 60%;
    position: relative;
    /* padding-top: -15px; */
    /* padding-right: 5px;
    padding-left: 5px; */
    padding-bottom: 4px;
  
    /* max-width: 100%; */
    height: auto;
    /* margin-right: 10px; */
    /* color: white; */
  
    /* margin-top: 50%; */    
  }

  .hero-btns {
    max-width: 25%;
    display: none;
    top: 40px;
    left: 12%;
  }

  .hero-btns-2 {
    max-width: 25%;
    display: none;
    top: 70px;
    left: 62%;
  }

  .hero-btns-3 {
    max-width: 25%;
    display: none;
    top: 70px;
    left: 62%;
  }

  .slide2 {
    height: auto;
  }

  .slide2-t {
    font-size: 20px;
  }

  .slide2-text-e {
    font-size: 15px;
    margin-top: -60px;
  }
  .slide2-text-p {
    font-size: 11px;
    margin-top: -60px;
  }

  .slide3 {
    height: auto;
  }

  .slide3-text-p1 {
    font-size: 20px;
  }

  .slide3-text-e {
    font-size: 15px;
    margin-top: -60px;
  }
  .slide3-text-p {
    font-size: 11px;
    margin-top: -20px;
  }

  .slide-title-3 {
    padding-top: 1px;
    display: none !important;
  }

  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .slide1-text {
    top: 43px;
    padding-right: 2px;
    
  }

  .slide2-text {
    top: 57px;
    padding-left: 30px;
  }

  .slide3-text {
    top: 57px;
  }

  /* .slide2-text {
    font-size: 18px;
    top: 37px;
    padding-left: 30px;
  } */

  /* .slide2-text-p {
    font-size: 7.5px;
    padding-top: 10px;
    padding-left: -1px;
    padding-right: -1px;
  } */

  .slide-title-2 {
    display: none !important;
  }

  .slide-title-3 {
    padding-bottom: 5px;
  }

  /* .slide3-text {
    font-size: 18px;
    padding-top: 10px;
    top: 37px;
  }

  .slide3-text-p1 {
    font-size: 20px;
    padding-top: 13px;
  }

  .slide3-text-p {
    font-size: 10px;
    padding-top: 10px;
  } */

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}